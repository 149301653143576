import * as TYPES from '../actionTypes'

export const changeTab = tab => dispatch => dispatch({
    type: TYPES.NAVIGATION_CHANGE_ACTIVE_TAB,
    payload: tab
})

export const show = (bool = true) => dispatch => dispatch({
    type: TYPES.NAVIGATION_SHOW,
    payload: bool
})
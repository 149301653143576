import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Paper, withStyles } from '@material-ui/core'
import { Delete as DeleteIcon, History as HistoryIcon, Add as AddIcon } from '@material-ui/icons'
import SimpleTable from '../../common/SimpleTable'
import ConfirmationDialog from '../../common/ConfirmationDialog'
import UserForm from './UserForm'
import * as USER from '../../../redux/actions/users'
import UserHistory from './UserHistory'
import * as CONSTANTS from '../../../utils/constants'
import * as NOTIFICATION from '../../../utils/notification'
import * as NAVIGATION from '../../../redux/actions/navigation'

const styles = () => ({

})

class Users extends Component {

    state = {
        users: [],
        renderPage: false,
        openUserModal: false,
        userModalType: CONSTANTS.CREATE,
        openConfirmationModal: false,
        openHistoryModal: false,
        selectedUserId: null,
        userFullName: '',
        userAddress: '',
        userToEdit: null,
        userToDelete: ''
    }

    componentDidMount() {
        this.props.changeTab(0)
        this.getAll()
    }

    getAll = () => this.props.getAll().then(response =>
        this.setState({ users: response.users.map(user => ({
            fullName: user.fullName,
            email: user.email,
            telephone: user.telephone,
            _cif: user.cif,
            _address: user.address,
            _office: user.office,
            _username: user.username,
            isActive: user.isActive ? this.props.languageState.i18n.yes : this.props.languageState.i18n.no,
            isAdmin: user.isAdmin ? this.props.languageState.i18n.yes : this.props.languageState.i18n.no,
            _id: user._id
        })), renderPage: true, openConfirmationModal: false, openUserModal: false })
    )

    createUser = user => {
        this.props.create(user).then(() => {
            NOTIFICATION.success(this.props.languageState.i18n.users.created)
            this.getAll()
        }).catch(() => {
            NOTIFICATION.error(this.props.languageState.i18n.users.createdError)
        })
    }

    editUser = user => {
        this.props.edit(this.state.selectedUserId, user).then(() => {
            NOTIFICATION.success(this.props.languageState.i18n.users.edited)
            this.getAll()
        }).catch(() => {
            NOTIFICATION.error(this.props.languageState.i18n.users.editedError)
        })
    }

    deleteUser = () => {
        this.props.delete(this.state.selectedUserId).then(() => {
            NOTIFICATION.success(this.props.languageState.i18n.deleted)
            this.getAll()
        }).catch(() => {
            NOTIFICATION.error(this.props.languageState.i18n.deletedError)
        })
    }


    render() {
        const { i18n } = this.props.languageState
        return this.state.renderPage ? (
            <Paper>
                <UserForm
                    open={this.state.openUserModal}
                    type={this.state.userModalType}
                    userToEdit={this.state.userToEdit}
                    key={this.state.userToEdit}
                    onAccept={this.state.userModalType === CONSTANTS.CREATE ? this.createUser : this.editUser}
                    onClose={() => this.setState({ openUserModal: false, userToEdit: null })}
                />
                <UserHistory
                    open={this.state.openHistoryModal}
                    userId={this.state.selectedUserId}
                    fullName={this.state.userFullName}
                    cif={this.state.cif}
                    address={this.state.userAddress}
                    onClose={() => this.setState({ openHistoryModal: false })}
                />
                <ConfirmationDialog
                    open={this.state.openConfirmationModal}
                    cancelButtonText={i18n.cancel}
                    acceptButtonText={i18n.accept}
                    onAccept={this.deleteUser}
                    onClose={() => this.setState({ openConfirmationModal: false })}
                    onCancel={() => this.setState({ openConfirmationModal: false })}
                    text={`${i18n.users.confirmDelete} ${this.state.userToDelete}?`}
                />
                <SimpleTable
                    header={['#', i18n.users.fullName,  i18n.users.email, i18n.users.phone, i18n.users.active, i18n.users.admin]}
                    rows={this.state.users}
                    onClickRow={row => this.setState({openUserModal: true, userModalType: CONSTANTS.EDIT, userToEdit: row, selectedUserId: row._id })}
                    title='Utilizatori'
                    actionsTitle='Actiuni'
                    actions={[
                        {
                            tooltip: i18n.delete,
                            onClick: (event, row) => this.setState({ openConfirmationModal: true, selectedUserId: row._id, userToDelete: `${row.fullName}` }),
                            icon: <DeleteIcon />
                        },
                        {
                            tooltip: i18n.users.seeHistory,
                            onClick: (event, row) => this.setState({ openHistoryModal: true, selectedUserId: row._id, userFullName: row.fullName, cif: row._cif, userAddress: row._address }),
                            icon: <HistoryIcon />
                        }
                    ]}
                    headerButton={{
                        tooltip: i18n.add,
                        icon: <AddIcon />,
                        onClick: () => this.setState({ openUserModal: true, userModalType: CONSTANTS.CREATE, userToEdit: undefined })
                    }}
                />
            </Paper >
        ) : null
    }
}

const mapStateToProps = state => ({
    languageState: state.languageReducer
})

const mapDispatchToProps = dispatch => ({
    changeTab: tab => dispatch(NAVIGATION.changeTab(tab)),
    getAll: () => dispatch(USER.getAll()),
    create: user => dispatch(USER.create(user)),
    delete: userId => dispatch(USER.deleteById(userId)),
    edit: (userId, user) => dispatch(USER.editById(userId, user))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Users))
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Table, TableHead, TableRow, TableBody, TableCell, Typography, Checkbox, withStyles } from '@material-ui/core'
import SimpleModal from '../../common/SimpleModal'
import * as HISTORY from '../../../redux/actions/history'
import * as NOTIFICATION from '../../../utils/notification'
import Printer from '../../common/Printer'

const styles = () => ({
    title: {
        fontSize: '1.25em',
        padding: '0px 0px 15px 0px'
    },
    headerText: {
        width: '100%',
        fontSize: '1.25em',
        textAlign: 'center',
        paddingBottom: '10px'
    },
    address: {
        fontSize: '0.75em',
        padding: '10px 0px 0px 0px'
    }
})

class UserHistory extends Component {

    constructor(props) {
        super(props)
        this.invoicedMap = {}
    }

    state = {
        reports: [],
        fullName: '',
        address: '',
        cif: '',
        checkedList: [],
        invoicedNow: [],
        openPrinter: false
    }

    static getDerivedStateFromProps(props, state) {
        if (state.fullName !== props.fullName)
            return {
                fullName: props.fullName,
                address: props.address,
                cif: props.cif
            }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {
            this.props.getByUserId(this.props.userId).then(reports => {
                this.invoicedMap = {}
                let reportsFiltered = reports.filter(report => report.invoiced)
                this.setState({
                    reports,
                    checkedList: reportsFiltered.map(report => report._id),
                    invoicedNow: [],
                    openPrinter: false
                })
                reportsFiltered.forEach(filter => {
                    this.invoicedMap[filter._id] = true
                })
            })
        }
    }

    check(report) {
        let reportId = report._id
        let checkedListCopy = [...this.state.checkedList]
        let invoicedNowCopy = [...this.state.invoicedNow]
        let index = checkedListCopy.indexOf(reportId)
        if (index > -1) {
            checkedListCopy.splice(index, 1)
            let findIndex = invoicedNowCopy.findIndex(copy => copy._id = reportId)
            if (findIndex > -1)
                invoicedNowCopy.splice(findIndex, 1)
        } else {
            checkedListCopy.push(reportId)
            if (!this.invoicedMap[reportId])
                invoicedNowCopy.push(report)
        }
        this.setState({ checkedList: checkedListCopy, invoicedNow: invoicedNowCopy })
    }

    onAccept = () => {
        this.props.updateInvoiced(this.props.userId, this.state.checkedList).then(() => {
            NOTIFICATION.success(this.props.languageState.i18n.users.userHistoryChanged)
            this.props.onClose()
            if (this.state.invoicedNow.length > 0)
                this.setState({ openPrinter: true })
            this.invoicedMap = {}
            this.state.checkedList.forEach(id => {
                this.invoicedMap[id] = true
            })
        }).catch(() => {
            NOTIFICATION.error(this.props.languageState.i18n.users.userHistoryChangedError)
            this.props.onClose()
        })
    }

    render() {
        const { classes } = this.props
        const { i18n } = this.props.languageState
        return (<>
            <Printer
                header={<>
                    <Typography className={this.props.classes.headerText}> REZERVARI DE FACTURAT </Typography>
                    <div>
                <div style={{width: '100%'}}>Nume: {this.state.fullName}  {this.state.cif && <span style={{float: 'right'}}>CIF: {this.state.cif}</span>}</div>
                        <div className={this.props.classes.address}>Adresa: {this.state.address}</div>
                    </div>
                </>}
                open={this.state.openPrinter}
                onClose={() => this.setState({ openPrinter: false, invoicedNow: [] })}
            >
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '30px' }} align="center">#</TableCell>
                            <TableCell align="center">{i18n.date}</TableCell>
                            <TableCell align="center">{i18n.interval}</TableCell>
                            <TableCell align="center">{i18n.status}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.invoicedNow.map((report, index) =>
                            <TableRow>
                                <TableCell align="center" component="td" scope="row">{index + 1}</TableCell>
                                <TableCell align="center">{moment(report.dayDateTime).format('DD.MM.YYYY')}</TableCell>
                                <TableCell align="center">{report.dayId.intervals[report.intervalIndex].key}</TableCell>
                                <TableCell align="center">{report.status}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Printer>
            <SimpleModal
                open={this.props.open}
                title={i18n.history}
                acceptButtonText={i18n.users.invoiced}
                cancelButtonText={i18n.close}
                onCancel={this.props.onClose}
                onAccept={this.onAccept}
                onClose={this.props.onClose}
            >
                <Typography component="h2" className={classes.title}> Nume si prenume: {this.state.fullName} </Typography>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '30px' }} align="center">#</TableCell>
                            <TableCell align="center">{i18n.date}</TableCell>
                            <TableCell align="center">{i18n.interval}</TableCell>
                            <TableCell align="center">{i18n.status}</TableCell>
                            <TableCell align="center">{i18n.users.invoiced}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.reports.map((report, index) =>
                            <TableRow>
                                <TableCell align="center" component="td" scope="row">{index + 1}</TableCell>
                                <TableCell align="center">{moment(report.dayDateTime).format('DD.MM.YYYY')}</TableCell>
                                <TableCell align="center">{report.dayId.intervals[report.intervalIndex].key}</TableCell>
                                <TableCell align="center">{report.status}</TableCell>
                                <TableCell align="center"><Checkbox
                                    checked={this.state.checkedList.indexOf(report._id) > -1}
                                    onClick={() => this.check(report)}
                                    color="primary"
                                /></TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </SimpleModal>
        </>
        )
    }
}

const mapStateToProps = state => ({
    languageState: state.languageReducer
})

const mapDispatchToProps = dispatch => ({
    getByUserId: userId => dispatch(HISTORY.getByUserId(userId)),
    updateInvoiced: (userId, invoiceList) => dispatch(HISTORY.updateInvoiced(userId, invoiceList))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserHistory))
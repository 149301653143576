import React, { Component } from 'react'
import { connect } from 'react-redux'
import validator from 'validator'
import { TextField, FormControlLabel, Switch, withStyles } from '@material-ui/core'
import SimpleModal from '../../common/SimpleModal'
import ConfirmationModal from '../../common/ConfirmationDialog'
import * as WORKER from '../../../redux/actions/worker'
import * as CONSTANTS from '../../../utils/constants'
import * as USER from '../../../redux/actions/users'
import * as NOTIFICATION from '../../../utils/notification'

const styles = () => ({

})

class UserForm extends Component {

    state = {
        name: '',
        address: '',
        office: '',
        email: '',
        telephone: '',
        username: '',
        cif: '',
        isAdmin: false,
        isActive: false,
        userId: null,
        usernameError: false,
        cifError: false,
        nameErorr: false,
        officeError: false,
        telephoneError: false,
        addressError: false,
        emailError: false,
        openConfirmation: false
    }

    static getDerivedStateFromProps(nextProps, state) {
        if (nextProps.userToEdit && (nextProps.userToEdit._id !== state.userId)) {
            let user = nextProps.userToEdit
            return {
                name: user.fullName,
                address: user._address,
                office: user._office,
                username: user._username,
                email: user.email,
                cif: user._cif,
                telephone: user.telephone,
                isAdmin: user.isAdmin === nextProps.languageState.i18n.yes ? true : false,
                isActive: user.isActive === nextProps.languageState.i18n.yes ? true : false,
                userId: user._id
            }
        }
        return null
    }

    onChange = event => this.setState({ [event.target.name]: event.target.value })

    onChangeCheckbox = event => {
        this.setState({ [event.target.name]: !this.state[event.target.name] })
    }

    onAccept = () => {

        let allGood = true
        let defaultErrors = {
            usernameError: false,
            nameErorr: false,
            officeError: false,
            telephoneError: false,
            cifError: false,
            addressError: false,
            emailError: false
        }
        this.setState({ ...defaultErrors })

        if (validator.isEmpty(this.state.username)) {
            allGood = false
            defaultErrors.usernameError = true
        }

        if (validator.isEmpty(this.state.name)) {
            allGood = false
            defaultErrors.nameError = true
        }

        if (validator.isEmpty(this.state.address)) {
            allGood = false
            defaultErrors.addressError = true
        }

        if (validator.isEmpty(this.state.cif)) {
            allGood = false
            defaultErrors.cifError = true
        }

        if (validator.isEmpty(this.state.office)) {
            allGood = false
            defaultErrors.officeError = true
        }

        if (validator.isEmpty(this.state.telephone)) {
            allGood = false
            defaultErrors.telephoneError = true
        }

        if (validator.isEmpty(this.state.email)) {
            allGood = false
            defaultErrors.emailError = true
        }

        if (!allGood) {
            return this.setState({ ...defaultErrors })
        }

        this.props.onAccept({
            fullName: this.state.name,
            address: this.state.address,
            office: this.state.office,
            email: this.state.email,
	    cif: this.state.cif,
            username: this.state.username,
            telephone: this.state.telephone,
            isAdmin: this.state.isAdmin,
            isActive: this.state.isActive
        })
    }

    resetPassword = () => {
        this.props.resetPassword(this.state.userId).then(() => {
            NOTIFICATION.success("Parola a fost resetata")
            this.setState({ openConfirmation: false })
        }).catch(() => {
            NOTIFICATION.error("Parola nu a putut fi resetata")
        })
    }

    render() {
        const { i18n } = this.props.languageState
        return (<>
            <ConfirmationModal
                open={this.state.openConfirmation}
                cancelButtonText="Inchide"
                acceptButtonText="Reseteaza"
                text={`Resetati parola pentru utilizatorul ${this.state.name}?`}
                onClose={() => this.setState({ openConfirmation: false })}
                onCancel={() => this.setState({ openConfirmation: false })}
                onAccept={this.resetPassword}
            />
            <SimpleModal
                open={this.props.open}
                title={this.props.type === CONSTANTS.CREATE ? "Creeaza utilizator" : "Editeaza utilizator"}
                acceptButtonText={this.props.type === CONSTANTS.CREATE ? "Creeaza" : "Salveaza"}
                cancelButtonText={i18n.close}
                deleteButtonText={this.state.userId ? "Reset password" : null}
                onDelete={() => this.setState({ openConfirmation: true })}
                onCancel={this.props.onClose}
                onClose={this.props.onClose}
                onAccept={this.onAccept}
                size='xs'
            >

                <TextField
                    fullWidth={true}
                    margin='dense'
                    error={this.state.usernameError}
                    value={this.state.username}
                    name='username'
                    label={i18n.users.username}
                    type='text'
                    onChange={event => this.onChange(event)}
                />

                <TextField
                    fullWidth={true}
                    margin='dense'
                    error={this.state.nameError}
                    value={this.state.name}
                    name='name'
                    label={i18n.users.fullName}
                    type='text'
                    onChange={event => this.onChange(event)}
                />


                <TextField
                    fullWidth={true}
                    margin='dense'
                    error={this.state.cifError}
                    value={this.state.cif}
                    name='cif'
                    label={i18n.users.cif}
                    type='text'
                    onChange={event => this.onChange(event)}
                />

                <TextField
                    fullWidth={true}
                    margin='dense'
                    error={this.state.officeError}
                    value={this.state.office}
                    name='office'
                    label={i18n.users.office}
                    type='text'
                    onChange={event => this.onChange(event)}
                />

                <TextField
                    fullWidth={true}
                    margin='dense'
                    error={this.state.emailError}
                    value={this.state.email}
                    name='email'
                    label={i18n.users.email}
                    type='text'
                    onChange={event => this.onChange(event)}
                />

                <TextField
                    fullWidth={true}
                    margin='dense'
                    error={this.state.telephoneError}
                    value={this.state.telephone}
                    name='telephone'
                    label={i18n.users.phone}
                    type='text'
                    onChange={event => this.onChange(event)}
                />

                <TextField
                    fullWidth={true}
                    margin='dense'
                    error={this.state.addressError}
                    value={this.state.address}
                    name='address'
                    label={i18n.users.address}
                    type='text'
                    multiline={true}
                    onChange={event => this.onChange(event)}
                />

                <div style={{ display: 'flex' }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={this.state.isActive}
                                value={this.state.isActive}
                                onChange={this.onChangeCheckbox}
                                name="isActive"
                            />
                        }
                        label={i18n.users.active}
                    />

                    <FormControlLabel
                        style={{ marginLeft: 'auto' }}
                        control={
                            <Switch
                                checked={this.state.isAdmin}
                                value={this.state.isAdmin}
                                onChange={this.onChangeCheckbox}
                                name="isAdmin"
                            />
                        }
                        label={i18n.users.admin}
                    />
                </div>
            </SimpleModal>
        </>
        )
    }
}

const mapStateToProps = state => ({
    languageState: state.languageReducer
})

const mapDispatchToProps = dispatch => ({
    getWorkerServices: () => dispatch(WORKER.getServices()),
    resetPassword: userId => dispatch(USER.resetPassword(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(UserForm))

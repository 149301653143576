import React, { Component } from 'react'
import { TextField, Button, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { Lock } from '@material-ui/icons'
import sha256 from 'sha256'
import validator from 'validator'
import FormModal from '../common/FormModal'
import * as NOTIFICATION from '../../utils/notification'
import * as LOGIN from '../../redux/actions/login'

const initialFields = [
    { name: 'username', label: 'Nume utilizator', type: 'text', value: '' },
    { name: 'email', label: 'Email', type: 'text', value: '' }
]


class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            username: '',
            password: '',
            buttonText: 'Login',
            emptyUsername: false,
            openModal: false,
            emptyPassword: false,
            resetPasswordFields: [...initialFields].map(a => ({...a}))
        }
    }

    login() {
        //reset error state
        let initialCondition = {
            emptyUsername: false,
            emptyPassword: false
        }
        this.setState(initialCondition)
        let allGood = true
        let emptyFields = { ...initialCondition }
        if (validator.isEmpty(this.state.username)) {
            emptyFields.emptyUsername = true
            allGood = false
        }
        if (validator.isEmpty(this.state.password)) {
            emptyFields.emptyPassword = true
            allGood = false
        }

        if (!allGood) {
            this.setState(emptyFields)
            return;
        }
        this.setState({
            isLoading: true
        })
        this.props.login(this.state.username, sha256(this.state.password))
            .then(response => {
                this.props.isLogged(true, response._id, response.acceptedTerms)
            })
            .catch(err => {
                NOTIFICATION.error("Datele sunt gresite")
                this.setState({ isLoading: false })
            })
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    resetPassword = data => {
        this.props.resetPassword(data.fields.username, data.fields.email)
            .then(() => {
                this.setState({openModal: false})
                NOTIFICATION.success("Parola a fost resetata cu succes. Veti primi un mail de confirmare")
            })
            .catch(() => {
                this.setState({openModal: false})
                NOTIFICATION.error("Nu s-a putut reseta parola")
            })
    }

    render() {
        return (
            <div className='loginContainer'>
                <FormModal
                    fields={this.state.resetPasswordFields}
                    key={this.state.openModal}
                    open={this.state.openModal}
                    onCancel={() => this.setState({ openModal: false })}
                    onClose={() => this.setState({ openModal: false })}
                    onSave={this.resetPassword}
                    dialogTitle={"Reseteaza parola"}
                    submitButtonText={"Reseteaza"}
                    cancelButtonText={"Anuleaza"}
                    type={'edit'}
                    maxWidth='xs'
                    validate={true}
                />
                <div className='login'>
                    <Lock fontSize='large' color='primary' />
                    <TextField
                        className='loginInput'
                        label='Username'
                        onChange={event => this.onChange(event)}
                        error={this.state.emptyUsername ? true : false}
                        name='username'
                    />
                    <TextField
                        className='loginInput'
                        label='Password'
                        type='password'
                        error={this.state.emptyPassword ? true : false}
                        onChange={event => this.onChange(event)}
                        onKeyPress={event => event.key === 'Enter' ? this.login() : null}
                        name='password'
                    />
                    <span className='forgotPassword' onClick={() => this.setState({openModal: true})}>Ati uitat parola?</span>
                    <Button className='loginButton' variant="contained" onClick={() => this.login()} color="primary">
                        {this.state.isLoading ? <CircularProgress style={{ color: '#ffffff' }} size={24} /> : 'Login'}
                    </Button>
                </div>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    resetPassword: (username, email) => dispatch(LOGIN.resetPassword(username, email)),
    login: (username, password) => dispatch(LOGIN.login(username, password))
})

const mapStateToProps = state => ({ ...state })

export default connect(mapStateToProps, mapDispatchToProps)(Login)
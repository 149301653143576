import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@material-ui/core'

class SimpleModal extends Component {

    render() {
        return (
            <Dialog
                open={this.props.open}
                fullWidth={true}
                onClose={() => this.props.onClose()}
                fullScreen={this.props.fullScreen || false}
                maxWidth={this.props.size || 'md'}
            >
                <DialogTitle>
                    {this.props.title}
                </DialogTitle>
                <DialogContent>
                    {this.props.children}
                </DialogContent>
                <DialogActions>
                    {this.props.deleteButtonText && <Button style={{float: 'left'}} onClick={() => this.props.onDelete()} color="secondary">
                        {this.props.deleteButtonText}
                    </Button>}
                    {this.props.cancelButtonText && <Button onClick={() => this.props.onCancel()} color="primary">
                        {this.props.cancelButtonText}
                    </Button>}
                    {this.props.acceptButtonText && <Button onClick={() => this.props.onAccept()} color="primary">
                        {this.props.acceptButtonText}
                    </Button>}
                </DialogActions>
            </Dialog>
        )
    }
}

SimpleModal.propTypes = {
    open: PropTypes.bool.isRequired,
    cancelButtonText: PropTypes.string,
    acceptButtonText: PropTypes.string,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
}

export default SimpleModal
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FormControl, Select, MenuItem, withStyles } from '@material-ui/core'
import * as LANGUAGE from '../../redux/actions/language'

const styles = theme => ({
    language: {
        margin: theme.spacing.unit,
        minWidth: 25,
        borderBottom: 'none'
    },
    languageItem: {
        color: '#ffffff'
    },
    selectIcon: {
        color: '#ffffff'
    }
})

class LanguageSelector extends Component{

    handleLanguageChange = event => {
        this.props.changeLanguage(event.target.value)
    }

    render(){
        return (<FormControl className={this.props.classes.language}>
            <Select
                classes={{
                    icon: this.props.classes.selectIcon,
                    root: this.props.classes.languageItem,
                    select: this.props.classes.select,
                    outlined: this.props.classes.selectOutline
                }}
                value={this.props.languageReducer.language}
                onChange={this.handleLanguageChange}
                inputProps={{
                    name: 'language'
                }}
            >
                <MenuItem value='RO'>{this.props.languageReducer.i18n.ro}</MenuItem>
                <MenuItem value='EN'>{this.props.languageReducer.i18n.en}</MenuItem>
            </Select>
        </FormControl>)
    }
}

const mapStateToProps = state => {
    return {
        languageReducer: state.languageReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeLanguage: lang => dispatch(LANGUAGE.changeLanguage(lang)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LanguageSelector))
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { AppBar, Toolbar, Tabs, Tab, withStyles, IconButton } from '@material-ui/core'
import { Settings as SettingsIcon, Home as HomeIcon } from '@material-ui/icons'
import { AccountCircle as AccountIcon } from '@material-ui/icons'
import { Link, withRouter } from 'react-router-dom'
import TooltipMenu from './subComponents/TooltipMenu'
import LanguageSelector from './LanguageSelector'
import FormModal from '../common/FormModal'
import * as LOGIN from '../../redux/actions/login'
import * as NAVIGATION from '../../redux/actions/navigation'
import * as USER from '../../redux/actions/users'
import * as NOTIFICATION from '../../utils/notification'

const styles = theme => ({
    tabs: {
        backgroundColor: theme.palette.background.paper,
        minHeight: '40px',
        color: 'rgba(0, 0, 0, 0.87)'
    },
    tab: {
        height: '40px',
        minHeight: '40px',
    },
    selected: {
        color: theme.palette.greenColor
    },
    selectRoot: {
        color: '#ffffff'
    },
    selectItem: {
        padding: '20px 30px 20px 25px',
        width: '200px'
    },
    selectIcon: {
        color: '#ffffff'
    }
})

const initialFields = [
    { name: 'fullName', label: 'Nume si prenume', type: 'text', value: '' },
    { name: 'cif', label: 'CIF', type: 'text', value: '' },
    { name: 'office', label: 'Nume Clinica', type: 'text', value: '' },
    { name: 'email', label: 'Adresa de email', type: 'text', value: '' },
    { name: 'telephone', label: 'Numar telefon', type: 'text', value: '' },
    { name: 'address', label: 'Adresa cabinet', type: 'text', value: '', multiline: true },
]


class Topbar extends Component {
    handleChange = (event, value) => {
        this.props.changeTab(value)
    }

    userMenuSelect = data => {
        if (data === 'logout') {
            return this.props.logout()
        }
        this.props.getProfile().then(response => {
            let modalFieldsCopy = [...this.state.modalFields].map(field => ({ ...field, value: response[field.name] || field.value }))
            this.setState({
                openModal: true,
                modalFields: modalFieldsCopy
            })
        })
    }

    saveProfile = object => {
        this.props.saveProfile(object.fields).then(() => {
            NOTIFICATION.success("Profilul a fost modificat")
            this.setState({ openModal: false })
        }).catch(() => {
            NOTIFICATION.error("Profilul nu a putut fi modificat")
            this.setState({ openModal: false })
        })
    }

    state = {
        openModal: false,
        modalFields: [...initialFields].map(json => ({ ...json }))
    }

    render() {
        return (
            <AppBar position="fixed">
                <Toolbar>
                    {this.props.loginState.isAdmin && !this.props.navigationState.show && <IconButton component={Link} style={{ color: '#ffffff' }} to='/admin'><SettingsIcon
                        color='inherit' /></IconButton>}

                    {this.props.loginState.isAdmin && this.props.navigationState.show && <IconButton component={Link} style={{ color: '#ffffff' }} to='/'><HomeIcon
                        color='inherit' /></IconButton>}
                    <div style={{ marginLeft: 'auto', width: 'auto', display: 'flex' }}>
                        <span style={{marginRight: '5px', marginTop: '14px'}} >{this.props.loginState.fullName}</span>
                        <TooltipMenu
                            options={[
                                { value: this.props.languageState.i18n.account.logout, key: 'logout' },
                                { value: 'Administrare cont', key: 'account' }
                            ]}
                            tooltipStyle={{ marginTop: '40px' }}
                            onSelect={this.userMenuSelect}
                        >
                            <AccountIcon />
                        </TooltipMenu>
                        <LanguageSelector />
                    </div>
                </Toolbar>

                <FormModal
                    fields={this.state.modalFields}
                    key={this.state.openModal}
                    open={this.state.openModal}
                    onCancel={() => this.setState({ openModal: false })}
                    onClose={() => this.setState({ openModal: false })}
                    onSave={this.saveProfile}
                    dialogTitle={"Administrare cont"}
                    submitButtonText={"Salveaza"}
                    cancelButtonText={"Anuleaza"}
                    type={'edit'}
                    validate={true}
                />

                {this.props.navigationState.show && <Tabs
                    className={this.props.classes.tabs}
                    fullWidth
                    value={this.props.navigationState.activeTab}
                    onChange={this.handleChange}>
                    <Tab classes={{ selected: this.props.classes.selected, root: this.props.classes.tab }} component={Link} to='/admin' label={this.props.languageState.i18n.nav.users} />
                    <Tab classes={{ selected: this.props.classes.selected, root: this.props.classes.tab }} className={this.props.classes.tab} component={Link} to='/admin/days' label={this.props.languageState.i18n.nav.days} />
                    <Tab classes={{ selected: this.props.classes.selected, root: this.props.classes.tab }} className={this.props.classes.tab} component={Link} to='/admin/reports' label={this.props.languageState.i18n.nav.reports} />
                    <Tab classes={{ selected: this.props.classes.selected, root: this.props.classes.tab }} className={this.props.classes.tab} component={Link} to='/admin/settings' label={this.props.languageState.i18n.nav.settings} />
                </Tabs>}
            </AppBar>
        )
    }
}

const mapStateToProps = state => {
    return {
        languageState: state.languageReducer,
        loginState: state.loginReducer,
        navigationState: state.navigationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        changeTab: tab => dispatch(NAVIGATION.changeTab(tab)),
        getProfile: () => dispatch(USER.getProfile()),
        saveProfile: profile => dispatch(USER.saveProfile(profile)),
        logout: () => dispatch(LOGIN.logout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Topbar)))
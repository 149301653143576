import React, { Component } from 'react'
import { TextField, Button, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import sha256 from 'sha256'
import validator from 'validator'
import * as USER from '../../redux/actions/users'
import * as NOTIFICATION from '../../utils/notification'
import * as LOGIN from '../../redux/actions/login'

class ResetPassword extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            username: '',
            password: '',
            passwordRetype: '',
            buttonText: 'Login',
            emptyUsername: false,
            emptyPassword: false,
            emptyPasswordRetype: false,
            renderPage: true
        }
    }

    componentDidMount() {
        this.props.getAccountByKey(this.props.userKey).then(response => {
            this.setState({
                username: response.username,
                renderPage: true
            })
        }).catch(() => {
            window.location.href = '/ '
        })
    }

    login() {
        //reset error state
        let initialCondition = {
            emptyUsername: false,
            emptyPassword: false,
            emptyPasswordRetype: false
        }

        this.setState(initialCondition)
        let allGood = true
        let emptyFields = { ...initialCondition }
        if (validator.isEmpty(this.state.username)) {
            emptyFields.emptyUsername = true
            allGood = false
        }
        if (validator.isEmpty(this.state.passwordRetype)) {
            emptyFields.emptyPasswordRetype = true
            allGood = false
        }
        if (validator.isEmpty(this.state.password)) {
            emptyFields.emptyPassword = true
            allGood = false
        }

        if (!allGood) {
            this.setState(emptyFields)
            return;
        }

        if (this.state.password !== this.state.passwordRetype) {
            NOTIFICATION.error("Parolele nu se potrivesc")
            return;
        }

        this.setState({
            isLoading: true
        })

        this.props.activateAccount(this.props.userKey, sha256(this.state.password))
            .then(response => {
                this.props.isLogged(true, response._id)
                NOTIFICATION.success("Contul a fost activat")
            })
            .catch(err => {
                NOTIFICATION.error("Nu s-a putut activa countul")
                this.setState({ isLoading: false })
            })
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    render() {
        if (this.state.renderPage) {
            return (
                <div className='loginContainer'>
                    <div className='login'>
                        <span style={{ margin: 'auto', fontSize: '20px' }}>Activeaza Cont</span>
                        <TextField
                            className='loginInput'
                            label='Username'
                            readOnly={true}
                            onChange={event => this.onChange(event)}
                            value={this.state.username}
                        />
                        <TextField
                            className='loginInput'
                            label='Parola'
                            type='password'
                            error={this.state.emptyPassword ? true : false}
                            onChange={event => this.onChange(event)}
                            onKeyPress={event => event.key === 'Enter' ? this.login() : null}
                            name='password'
                        />
                        <TextField
                            className='loginInput'
                            label='Reintroduceti parola'
                            type='password'
                            error={this.state.emptyPasswordRetype ? true : false}
                            onChange={event => this.onChange(event)}
                            onKeyPress={event => event.key === 'Enter' ? this.login() : null}
                            name='passwordRetype'
                        />
                        <Button className='loginButton' variant="contained" onClick={() => this.login()} color="primary">
                            {this.state.isLoading ? <CircularProgress style={{ color: '#ffffff' }} size={24} /> : 'Login'}
                        </Button>
                    </div>
                </div>
            )
        } else return null
    }
}

const mapDispatchToProps = dispatch => ({
    login: (username, password) => dispatch(LOGIN.login(username, password)),
    getAccountByKey: key => dispatch(USER.getAccountByKey(key)),
    activateAccount: (key, password) => dispatch(USER.activateAccountByKey(key, password))
})

const mapStateToProps = state => ({ ...state })

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)
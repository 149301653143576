import axios from '../../utils/axios'

export const get = (startDate, endDate) => () => axios.get(`/days?fromDate=${startDate}&endDate=${endDate}`)
    .then(daysResponse => Promise.resolve(daysResponse.data))
    .catch(err => Promise.reject(err))

export const populate = (startDate, endDate) => () => axios.put(`/days/populate`, { startDate, endDate })
    .then(daysResponse => Promise.resolve(daysResponse.data))
    .catch(err => Promise.reject(err))

export const rent = (date, intervalId, object) => () => axios.put(`/days/${date}/interval/${intervalId}`, object)
    .then(daysResponse => Promise.resolve(daysResponse.data))
    .catch(err => Promise.reject(err))

export const cancel = (date, intervalId, object) => () => axios.put(`/days/${date}/interval/${intervalId}`, object)
    .then(daysResponse => Promise.resolve(daysResponse.data))
    .catch(err => Promise.reject(err))

export const edit = (date, day) => () => axios.put(`/days/${date}`, { day })
    .then(daysResponse => Promise.resolve(daysResponse.data))
    .catch(err => Promise.reject(err))

export const createInterval = (date, interval) => () => axios.post(`/days/${date}`, { interval })
    .then(daysResponse => Promise.resolve(daysResponse.data))
    .catch(err => Promise.reject(err))

export const getSettingsHour = () => () => axios.get('/days/settingsHour').then(settingsResponse => Promise.resolve(settingsResponse.data.hour))

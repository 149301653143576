import React, { Component } from 'react'
import { IconButton, Tooltip } from '@material-ui/core'
import { Print as PrintIcon } from '@material-ui/icons'
import ReactToPrint from 'react-to-print'
import SimpleDialog from './SimpleModal'


class Printer extends Component {

    state = {
        reception: {},
        showTable: false
    }

    render() {
        return (<SimpleDialog
            open={this.props.open}
            fullWidth={true}
            onClose={this.props.onClose}
            onCancel={this.props.onClose}
            style={{ padding: '0px' }}
            cancelButtonText="Inchide"
            maxWidth={'lg'}
        >
            <ReactToPrint
                trigger={() => <Tooltip title="Printeaza"><IconButton style={{ float: 'right', padding: '20px', cursor: 'pointer' }} aria-label="Delete" ><PrintIcon color="secondary" /></IconButton></Tooltip>}
                content={() => this.componentRef} />
            <div className={`printerPage ${this.props.header && 'withHeader'} ${this.props.footer && 'widthFooter'}`} ref={el => this.componentRef = el}>
                <table style={{width: '100%'}}>
                    {this.props.header && <thead ><tr><td>
                        <div className={`header-space ${this.props.headerClass ? this.props.headerClass : ''}`}> <div className='printerHeader'>{this.props.header}</div> </div>
                    </td></tr></thead>}
                    <tbody><tr><td>
                        <div className={`printerBody`}>{this.props.children}</div>
                    </td></tr></tbody>
                    {this.props.footer && <tfoot ><tr><td>
                       <div className='footer-space'> <div className='printerFooter'>{this.props.footer}</div></div>
                    </td></tr></tfoot>}
                </table>
            </div>
        </SimpleDialog >
            )
        }
    }
    
export default Printer
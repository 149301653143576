import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Paper, Table, TableHead, TableRow, TableCell, Button, Typography, TableBody, IconButton, TextField, Tooltip, withStyles } from '@material-ui/core'
import { Add as AddIcon, ArrowDownward as ArrowIcon } from '@material-ui/icons'
import SimpleModal from '../../common/SimpleModal'
import ConfirmationModal from '../../common/ConfirmationDialog'
import * as DAYS from '../../../redux/actions/days'
import * as HISTORY from '../../../redux/actions/history'
import * as SETTINGS from '../../../redux/actions/settings'
import * as CONSTANTS from '../../../utils/constants'
import * as NOTIFICATION from '../../../utils/notification'
import * as NAVIGATION from '../../../redux/actions/navigation'
import 'moment/locale/ro'

const styles = () => ({
    free: {
        backgroundColor: 'rgb(6, 215, 156) !important',
        color: '#ffffff !important'
    },
    busy: {
        backgroundColor: 'rgb(252, 75, 108) !important',
        color: '#ffffff !important'
    },
    reserved: {
        backgroundColor: 'rgb(116, 90, 242) !important',
        color: '#ffffff !important'
    },
    reservedNr: {
        backgroundColor: '#f7c8d1 !important',
        display: 'block',
        minWidth: '15px',
        borderRadius: '50%',
        float: 'right',
        fontSize: '13px',
        color: '#454545'
    },
    devicesNr: {
        backgroundColor: 'rgb(255, 255, 255) !important',
        display: 'block',
        minWidth: '15px',
        borderRadius: '50%',
        marginLeft: '10px',
        float: 'right',
        fontSize: '13px',
        color: '#454545'
    },
    timeInterval: {
        display: 'block',
        maxWidth: '300px',
        width: '300px !important',
        paddingRight: '10px',
        textAlign: 'center',
        minHeight: '38px',
        lineHeight: '38px'
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '10px 10px',
        '& td': {
            width: '6.25%',
            padding: '0px 5px 0px 5px !important',
            cursor: 'pointer',
            fontSize: '1em',
            borderBottom: 'none',
            boxSizing: 'border-box',
            fontSize: '13px'
        },
        '& th': {
            width: '6.25%',
            padding: '10px 5px 10px 5px !important',
            textAlign: 'center',
            fontSize: '1em',
            backgroundColor: '#f5f5f5'
        },
        '& tr': {
            height: '30px'
        }
    },
    date: {
        width: '200px',
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        '& button': {
            fontSize: '1em',
            color: 'rgba(0, 0, 0, 0.54)',
            minWidth: '50px',
            paddingRight: '0px',
            paddingLeft: '0px'
        }
    },
    currentMonth: {
        width: '150px',
        textAlign: 'center',
        fontSize: '1.25em',
        color: 'rgba(0, 0, 0, 0.54)',
        marginTop: '4px',
        textTransform: 'capitalize'
    },
    dayDate: {
        display: 'block',
        fontSize: '0.85em',
        marginTop: '5px'
    },
    chip: {
        width: '100%',
        padding: '9px',
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        boxSizing: 'border-box',
        color: '#454545'
    },
    beforeNow: {
        backgroundColor: '#f5f5f5'
    },
    tableHead: {
        position: 'sticky',
        top: '0px'
    },
    addButton: {
        height: '30px',
        paddingTop: '5px'
    },
    afterNow: {
        borderBottom: '1px solid #e0e0e0 !important'
    }
})

const defaultIntervals = [
    { key: "9-10", from: 9, to: 10, display: false },
    { key: "10-11", from: 10, to: 11, display: false },
    { key: "11-12", from: 11, to: 12, display: false },
    { key: "12-13", from: 12, to: 13, display: false },
    { key: "13-14", from: 13, to: 14, display: false },
    { key: "14-15", from: 14, to: 15, display: false },
    { key: "15-16", from: 15, to: 16, display: false },
    { key: "16-17", from: 16, to: 17, display: false },
    { key: "17-18", from: 17, to: 18, display: false },
    { key: "18-19", from: 18, to: 19, display: false },
    { key: "19-20", from: 19, to: 20, display: false },
    { key: "20-21", from: 20, to: 21, display: false }
]

let defaultHead = [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]

class Days extends Component {

    state = {
        services: [],
        modalAcceptButtonText: '',
        modalCancelButtonText: null,
        openModal: false,
        modalText: '',
        modalTitle: '',
        reasonToCancel: '',
        defaultHeaders: [],
        startOfMonth: moment().startOf('month'),
        endOfMonth: moment().endOf('month'),
        populateStart: moment(),
        populateEnd: moment().endOf('month'),
        mergedWeekDays: [],
        reservedDays: [],
        renderPage: false,
        openIntervalModal: false,
        intervalDate: null,
        intervalDevicesNr: null,
        intervalTo: null,
        intervalFrom: null,
        intervalSelected: null,
        currentSelectedDay: null,
        intervalModalType: CONSTANTS.CREATE,
        openDeleteConfirmation: false,
        openPopulateInterval: false
    }

    componentDidMount() {
        this.props.changeTab(1)
        this.props.getSettings().then(response => {
            if(!response.defaultIntervals) response.defaultIntervals = []
            let defaultHeadCopy = [...defaultHead]
            let generateHeader = []
            let index = 0
            let indexI = 0
            let inserted = false
            for (let head of defaultHeadCopy) {
                inserted = false
                for (let interval of response.defaultIntervals) {

                    let split = interval.key.split('-')
                    if (head === Number(split[0])) {
                        let span = Number(split[1]) - Number(split[0])
                        generateHeader.push({
                            key: interval.key,
                            span: span,
                            addOne: true
                        })
                        defaultHeadCopy.splice(index, span - 1)
                        response.defaultIntervals.splice(indexI, 1)
                        inserted = true
                        break
                    }
                    indexI++
                }
                if (!inserted) {
                    generateHeader.push({
                        key: head,
                        span: 1,
                        addOne: false
                    })
                }
                index++
            }
            this.setState({ defaultHeaders: generateHeader })
            this.loadDays()
        })

    }

    goNextMonth = () => {
        let newMonthStart = this.state.startOfMonth.clone().add(1, 'months')
        let newMonthEnd = this.state.endOfMonth.clone().add(1, 'month')
        this.setState({
            startOfMonth: newMonthStart.startOf('month'),
            endOfMonth: newMonthEnd.endOf('month')
        }, () => this.loadDays())
    }

    goPrevMonth = () => {
        let newMonthStart = this.state.startOfMonth.clone().subtract(1, 'months')
        let newMonthEnd = this.state.endOfMonth.clone().subtract(1, 'month')
        this.setState({
            startOfMonth: newMonthStart.startOf('month'),
            endOfMonth: newMonthEnd.endOf('month')
        }, () => this.loadDays())
    }

    loadDays = () => this.props.getDays(this.state.startOfMonth.toISOString(), this.state.endOfMonth.toISOString()).then(
        daysResponse => this.setState({
            reservedDays: daysResponse,
            openIntervalModal: false,
            openDeleteConfirmation: false
        }, () => this.mergeDays())
    )

    openIntervalModal = day => {
        this.setState({
            intervalDate: day.dateTime,
            openIntervalModal: true,
            intervalModalType: CONSTANTS.CREATE,
            intervalFrom: null,
            intervalTo: null,
            intervalDevicesNr: null,
            intervalSelected: null,
            currentSelectedDay: day
        })
    }

    addInterval = () => {
        this.setState({ intervalSelected: null }, () => {
            if (!this.state.intervalFrom || !this.state.intervalTo || !this.state.intervalDevicesNr) {
                NOTIFICATION.error(this.props.languageState.i18n.completeAllForms)
                return
            }

            if (this.state.intervalDevicesNr < 0) {
                NOTIFICATION.error(this.props.languageState.i18n.greaterThenZero)
                return
            }

            if (this.overwriteCheck(this.state.currentSelectedDay)) {
                this.props.createInterval(
                    this.state.intervalDate,
                    {
                        key: `${this.state.intervalFrom}-${this.state.intervalTo}`,
                        devicesNr: this.state.intervalDevicesNr,
                        rentedDevices: 0
                    }
                ).then(() => {
                    NOTIFICATION.success(this.props.languageState.i18n.days.created)
                    this.loadDays()
                }).catch(() => {
                    NOTIFICATION.error(this.props.languageState.i18n.days.createError)
                })
            } else {
                NOTIFICATION.error(this.props.languageState.i18n.days.infoError)
            }
        })
    }

    openEdit = (day, interval) => {
        this.setState({
            intervalDate: day.dateTime,
            intervalFrom: interval.from,
            intervalTo: interval.to,
            intervalDevicesNr: interval.devicesNr,
            intervalSelected: interval,
            openIntervalModal: true,
            intervalModalType: CONSTANTS.EDIT
        })
    }

    deepCopy = json => JSON.parse(JSON.stringify(json))

    overwriteCheck = day => {
        let reserved = []

        let newDay = this.deepCopy(day)

        if (Number(this.state.intervalTo) <= 21 && Number(this.state.intervalTo) >= 10 &&
            Number(this.state.intervalFrom) >= 9 && Number(this.state.intervalFrom) <= 20) {
            if (Number(this.state.intervalTo) > Number(this.state.intervalFrom)) {
                let filterFunction = interval => interval._id && (this.state.intervalSelected && (interval._id !== this.state.intervalSelected._id))
                if (!this.state.intervalSelected) {
                    filterFunction = interval => interval._id
                }
                newDay.intervals.filter(filterFunction).forEach(interval => {
                    let split = interval.key.split('-')
                    for (let i = Number(split[0]) + 1; i <= Number(split[1]) - 1; i++) {
                        reserved.push(i)
                    }
                })
                for (let i = Number(this.state.intervalFrom); i <= Number(this.state.intervalTo); i++) {
                    if (reserved.indexOf(i) > -1) return false
                }
                return true
            } else return false
        } else return false
    }

    checkNoReservations = interval => Number(interval.rentedDevices) === 0

    editInterval = () => {
        let newKey = {}

        if (!this.state.intervalFrom || !this.state.intervalTo || this.state.intervalDevicesNr === null) {
            NOTIFICATION.error(this.props.languageState.i18n.completeAllForms)
            return
        }

        if (this.state.intervalDevicesNr < 0) {
            NOTIFICATION.error(this.props.languageState.i18n.greaterThenZero)
            return
        }

        this.state.mergedWeekDays.forEach(weekDayOrigin => {
            let weekDay = this.deepCopy(weekDayOrigin)
            if (moment(weekDay.dateTime).isSame(moment(this.state.intervalDate))) {
                weekDay.intervals = weekDay.intervals.filter(interval => interval._id).map(interval => {
                    if (interval.key === this.state.intervalSelected.key) {
                        newKey = `${this.state.intervalFrom}-${this.state.intervalTo}`
                        return {
                            _id: interval._id,
                            key: newKey,
                            devicesNr: this.state.intervalDevicesNr,
                            rentedDevices: interval.rentedDevices,
                            rentUsers: interval.rentUsers
                        }
                    }
                    return {
                        _id: interval._id,
                        key: interval.key,
                        devicesNr: interval.devicesNr,
                        rentedDevices: interval.rentedDevices,
                        rentUsers: interval.rentUsers
                    }
                })
                if (this.overwriteCheck(weekDay)) {
                    if (this.checkNoReservations(this.state.intervalSelected) || (this.state.intervalSelected.key === newKey && (this.state.intervalSelected.rentedDevices <= this.state.intervalDevicesNr))) {
                        this.props.editDay(weekDay.dateTime, weekDay).then(() => {
                            NOTIFICATION.success(this.props.languageState.i18n.days.edited)
                            this.loadDays()
                        }).catch(() => {
                            NOTIFICATION.error(this.props.languageState.i18n.days.editError)
                        })
                    } else {
                        NOTIFICATION.error(this.props.languageState.i18n.days.alreadyRent)
                    }
                }
                else {
                    NOTIFICATION.error(this.props.languageState.i18n.days.wrongHours)
                }
                return
            }
        })
    }

    openDeleteConfirmation = () => {
        this.setState({
            openDeleteConfirmation: true
        })
    }

    deleteInterval = () => {
        this.state.mergedWeekDays.forEach(weekDayOrigin => {
            let weekDay = this.deepCopy(weekDayOrigin)
            if (moment(weekDay.dateTime).isSame(moment(this.state.intervalDate))) {
                let filteredIntervals = weekDay.intervals.filter(interval => interval._id)
                let intervalIndex = filteredIntervals.findIndex(interval =>
                    interval.key === this.state.intervalSelected.key
                )
                if (intervalIndex > -1) {
                    if (this.checkNoReservations(filteredIntervals[intervalIndex])) {
                        this.props.countHistory(this.state.intervalSelected.dayId, intervalIndex).then(count => {
                            if (Number(count) > 0) {
                                this.setState({ openDeleteConfirmation: false })
                                return NOTIFICATION.error(this.props.languageState.i18n.days.historyError)
                            }
                            filteredIntervals.splice(intervalIndex, 1)
                            weekDay.intervals = filteredIntervals
                            this.props.editDay(weekDay.dateTime, weekDay).then(() => {
                                NOTIFICATION.success(this.props.languageState.i18n.days.deleted)
                                this.loadDays()
                            }).catch(() => {
                                this.setState({ openDeleteConfirmation: false })
                                NOTIFICATION.error(this.props.languageState.i18n.days.deleteError)
                            })
                        })
                    } else {
                        this.setState({ openDeleteConfirmation: false })
                        NOTIFICATION.error(this.props.languageState.i18n.days.deleteErrorReserved)
                    }
                    return;

                }
            }
        })
    }

    mergeDays = () => {
        let dates = []
        let index = 0
        for (let m = this.state.startOfMonth.clone(); m.isBefore(this.state.endOfMonth); m.add(1, 'days')) {

            //skip sunday
            if (m.day() === 0) continue

            dates[index] = { key: m.format('dd D.MM.YYYY'), intervals: [...defaultIntervals.map(a => a)], beforeNow: m.startOf('day').isBefore(moment().startOf('day')) ? true : false, dateTime: m.toISOString() }
            this.state.reservedDays.forEach(day => {
                if (moment(day.dateTime).isSame(m.toISOString(), 'day') && moment(day.dateTime).isSame(m.toISOString(), 'year')) {
                    let intervalFromMap = {}
                    day.intervals.forEach(reserve => intervalFromMap[reserve.key.split('-')[0]] = reserve)
                    for (let intervalIndex = 0; dates[index].intervals[intervalIndex]; intervalIndex++) {
                        let interval = dates[index].intervals[intervalIndex]
                        if (intervalFromMap[interval.from]) {
                            dates[index].intervals[intervalIndex] = {
                                ...intervalFromMap[interval.from],
                                from: Number(intervalFromMap[interval.from].key.split('-')[0]),
                                to: Number(intervalFromMap[interval.from].key.split('-')[1]),
                                rentedDevices: intervalFromMap[interval.from].rentedDevices,
                                dayId: day._id,
                                dateTime: m.toISOString(),
                                devicesNr: intervalFromMap[interval.from].devicesNr,
                                status: intervalFromMap[interval.from].devicesNr === 0 ? 'reserved' : intervalFromMap[interval.from].devicesNr > intervalFromMap[interval.from].rentedDevices ? 'free' : 'busy',
                                addOne: true
                            }
                            let skip = dates[index].intervals[intervalIndex].to - dates[index].intervals[intervalIndex].from
                            dates[index].intervals.splice(intervalIndex + 1, skip - 1)
                        }
                    }
                }

            })
            index++;
        }
        this.setState({ mergedWeekDays: dates, renderPage: true })
    }

    populate = () => this.props.populate(this.state.populateStart.toISOString(), this.state.populateEnd.toISOString())
        .then(() => {
            console.log(this.state.populateStart.toISOString(), this.state.populateEnd.toISOString())
            NOTIFICATION.success(this.props.languageState.i18n.populate.populated)
            this.loadDays()
            this.setState({ openPopulateInterval: false })
        }).catch(() => {
            NOTIFICATION.error(this.props.languageState.i18n.populate.populateError)
        })

    handleChange = event => this.setState({ [event.target.name]: event.target.value })

    render() {
        const { classes } = this.props
        const { i18n } = this.props.languageState
        return (
            this.state.renderPage && <Paper>
                <SimpleModal
                    open={this.state.openPopulateInterval}
                    onCancel={() => this.setState({ openPopulateInterval: false })}
                    onClose={() => this.setState({ openPopulateInterval: false })}
                    onAccept={this.populate}
                    cancelButtonText={this.props.languageState.i18n.cancel}
                    acceptButtonText={this.props.languageState.i18n.accept}
                    size='xs'
                    title={this.props.languageState.i18n.populateInterval}
                >
                    <TextField
                        fullWidth={true}
                        id="date"
                        className={classes.textField}
                        label={this.props.languageState.i18n.fromDate}
                        type="date"
                        onChange={event => this.setState({ populateStart: moment(event.target.value) })}
                        value={this.state.populateStart.format('YYYY-MM-DD')}
                        InputLabelProps={{
                            shrink: true
                        }}
                        min={moment().format('YYYY-MM-DD')}

                    />
                    <TextField
                        fullWidth={true}
                        id="date"
                        className={classes.textField}
                        label={this.props.languageState.i18n.toDate}
                        type="date"
                        onChange={event => this.setState({ populateEnd: moment(event.target.value) })}
                        value={this.state.populateEnd.format('YYYY-MM-DD')}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                </SimpleModal>
                <ConfirmationModal
                    open={this.state.openDeleteConfirmation}
                    text={`${i18n.deleteInterval} ${this.state.intervalSelected ? this.state.intervalSelected.key : ''}?`}
                    acceptButtonText={i18n.delete}
                    cancelButtonText={i18n.cancel}
                    onAccept={this.deleteInterval}
                    onCancel={() => this.setState({ openDeleteConfirmation: false })}
                    onClose={() => this.setState({ openDeleteConfirmation: false })}
                />
                <SimpleModal
                    open={this.state.openIntervalModal}
                    title={`${this.state.intervalModalType === CONSTANTS.EDIT ? 'Editeaza interval' : 'Adauga Interval'} - ${moment(this.state.intervalDate).format('DD/MM/YYYY')}`}
                    acceptButtonText={this.state.intervalModalType === CONSTANTS.CREATE ? "Adauga" : "Salveaza"}
                    cancelButtonText={i18n.close}
                    onCancel={() => this.setState({ openIntervalModal: false })}
                    onClose={() => this.setState({ openIntervalModal: false })}
                    onDelete={() => this.setState({ openDeleteConfirmation: true })}
                    deleteButtonText={this.state.intervalModalType === CONSTANTS.EDIT ? "Sterge" : null}
                    onAccept={this.state.intervalModalType === CONSTANTS.CREATE ? this.addInterval : this.editInterval}
                    size='xs'
                >
                    <TextField
                        fullWidth={true}
                        margin='dense'
                        error={this.state.intervalFromError}
                        value={this.state.intervalFrom}
                        name='intervalFrom'
                        label={i18n.fromHour}
                        type='number'
                        max="20"
                        min="9"
                        onChange={event => this.handleChange(event)}
                    />
                    <TextField
                        fullWidth={true}
                        margin='dense'
                        error={this.state.intervalToError}
                        value={this.state.intervalTo}
                        name='intervalTo'
                        label={i18n.toHour}
                        type='number'
                        max="20"
                        min="9"
                        onChange={event => this.handleChange(event)}
                    />
                    <TextField
                        fullWidth={true}
                        margin='dense'
                        error={this.state.intervalDevicesNrError}
                        value={this.state.intervalDevicesNr}
                        name='intervalDevicesNr'
                        label={i18n.devicesNr}
                        type='number'
                        onChange={event => this.handleChange(event)}
                    />
                </SimpleModal>
                <Table className={classes.table}>
                    <TableHead className={classes.tableHead}>
                        <TableRow>
                            <TableCell style={{ backgroundColor: '#ffffff' }} className={classes.timeInterval}>
                                <div className={classes.date}>
                                    <Button onClick={this.goPrevMonth}> {'<'} </Button>
                                    <Typography className={classes.currentMonth} component="h2"><div>{this.state.startOfMonth.format('MMMM')}</div><div>{this.state.startOfMonth.format('YYYY')}</div></Typography>
                                    <Button onClick={this.goNextMonth}> {'>'} </Button>
                                </div>
                            </TableCell>
                            {this.state.defaultHeaders.map(key => <TableCell component="th" colSpan={key.span} align="center">{key.key}</TableCell>)}
                            <TableCell style={{ backgroundColor: '#ffffff' }} component="th" align="center"><Tooltip title={i18n.days.populate}><IconButton onClick={() => this.setState({ openPopulateInterval: true })}><ArrowIcon color='secondary' /></IconButton></Tooltip></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.mergedWeekDays.map((day, dayIndex) =>
                            <TableRow key={dayIndex}>
                                <TableCell className={`${classes.timeInterval} ${day.beforeNow ? classes.beforeNow : classes.afterNow}`} component="td">{day.key}</TableCell>
                                {day.intervals.map(interval =>
                                    <TableCell onClick={() => interval.addOne && !day.beforeNow ? this.openEdit(day, interval) : null} key={interval.key} component="td" colSpan={Number(interval.to) - Number(interval.from)} align="center">
                                        <div className={`${classes[interval.status]} ${classes.chip}`}>
                                            <span className={classes.devicesNr}>{interval.devicesNr}</span>
                                            <span className={classes.reservedNr}>{interval.rentedDevices} </span>
                                            {interval.key}
                                        </div>
                                    </TableCell>
                                )}
                                {!day.beforeNow && <TableCell>
                                    <AddIcon className={classes.addButton} onClick={() => this.openIntervalModal(day)} color='secondary' />
                                </TableCell>}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Paper>
        )
    }
}

const mapStateToProps = state => ({
    languageState: state.languageReducer
})

const mapDispatchToProps = dispatch => ({
    changeTab: tab => dispatch(NAVIGATION.changeTab(tab)),
    populate: (startDate, endDate) => dispatch(DAYS.populate(startDate, endDate)),
    getSettings: () => dispatch(SETTINGS.get()),
    editDay: (dateTime, day) => dispatch(DAYS.edit(dateTime, day)),
    getDays: (startOfMonth, endOfMonth) => dispatch(DAYS.get(startOfMonth, endOfMonth)),
    createInterval: (date, interval) => dispatch(DAYS.createInterval(date, interval)),
    countHistory: (dayId, intervalIndex) => dispatch(HISTORY.countByDayAndInterval(dayId, intervalIndex))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Days))
import React, { Component } from 'react'
import { Table, TableCell, TableHead, TableRow, TableBody, Typography, Tooltip, IconButton, Paper, Toolbar, withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

const style = theme => ({
    tableBackground: {
        backgroundColor: theme.palette.common.white
    },
    spacer: {
        flex: '1 1 100%'
    },
    searchIcon: {
        fontSize: '21px'
    },
    searchInput: {
        fontSize: ''
    },
    searchInputUnderline: {
        '&:after': {
            borderBottom: '2px solid ' + theme.palette.redColor,
        }
    },
    actionTd: {
        width: '100px'
    },
    actionTh: {
        textAlign: 'center'
    }
})

class SimpleTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            searchInput: ''
        }
    }
    render() {
        return (
            <div>
                <Paper className={this.props.tableToolbarClass}>
                    <Toolbar className={this.props.classes.tableToolbar}>
                        <Typography variant="h6" id="tableTitle">
                            {this.props.title}
                        </Typography>
                        <div className={this.props.classes.spacer} />
                        <div className={this.props.classes.actions}>
                            {this.props.headerButton &&
                                <Tooltip title={this.props.headerButton.tooltip}>
                                    <IconButton onClick={this.props.headerButton.onClick} color={this.props.headerButton.color || 'secondary'} aria-label={this.props.headerButton.tooltip}>
                                        {this.props.headerButton.icon}
                                    </IconButton>
                                </Tooltip>}
                        </div>
                    </Toolbar>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {this.props.header.map(value => <TableCell>{value}</TableCell>)}
                                <TableCell className={this.props.classes.actionTh}> {this.props.actionsTitle} </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.props.rows.map((row, rowIndex) => {
                                let values = Object.values(row)
                                let keys = Object.keys(row)
                                return (
                                    <TableRow hover={true} onClick={event => {
                                        console.log(event.target.tagName)
                                        if (event.target.tagName.toLowerCase() !== 'button' && event.target.tagName !== 'path' && event.target.tagName !== 'svg')
                                            this.props.onClickRow(row)
                                    }} key={row.id}>
                                        <TableCell string>{rowIndex + 1}</TableCell>
                                        {values.map((value, index) => {
                                            if (keys[index] !== '_id' && keys[index].substr(0, 1) !== '_')
                                                return <TableCell string>{value}</TableCell>
                                            return null
                                        })}
                                        <TableCell className={this.props.classes.actionTd}>
                                            {this.props.actions.map(action => {
                                                if ((action.deleteIf && !action.deleteIf(row)) || !action.deleteIf) {
                                                    return <Tooltip title={action.tooltip} placement='top'>
                                                        <IconButton
                                                            onClick={event => action.onClick ? action.onClick(event, row) : () => { }}
                                                            color={action.color || 'primary'}
                                                            aria-label={action.tooltip || ''}>
                                                            {action.icon}
                                                        </IconButton>
                                                    </Tooltip>
                                                } else return null
                                            })}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        )
    }
}

SimpleTable.propTypes = {
    rows: PropTypes.array.isRequired,
    header: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    actionsTitle: PropTypes.string.isRequired,
    actions: PropTypes.array.isRequired,
    headerButton: PropTypes.object.isRequired,
    search: PropTypes.object.isRequired
}

export default withStyles(style)(SimpleTable)
import axios from '../../utils/axios'
import * as TYPES from '../actionTypes'

export const getAll = () => (dispatch, getState) => axios.get('/workers')
    .then(workersResponse => Promise.resolve(workersResponse.data))
    .catch(err => Promise.reject(err))

export const getReviews = id => (dispatch, getState) => axios.get(`/workers/${id}/review`)
    .then(reviewResponse => Promise.resolve(reviewResponse.data))
    .catch(err => Promise.reject(err))

export const create = worker => (dispatch, getState) => axios.post('/workers', worker)
    .then(workersResponse => Promise.resolve(workersResponse.data))
    .catch(err => Promise.reject(err))

export const createWorkerReview = (review, workerId) => (dispatch, getState) => axios.post(`/workers/${workerId}/review`, review)
    .then(workersResponse => Promise.resolve(workersResponse.data))
    .catch(err => Promise.reject(err))

export const createWorkerHelpRequest = workerId => (dispatch, getState) => axios.post(`/workers/${workerId}/service`)
    .then(workersResponse => Promise.resolve(workersResponse.data))
    .catch(err => Promise.reject(err))

export const getServices = () => (dispatch, getState) => axios.get(`/workers/service`)
    .then(workersResponse => Promise.resolve(workersResponse.data))
    .catch(err => Promise.reject(err))

export const deleteById = id => (dispatch, getState) => axios.delete(`/workers/${id}`)
    .then(workersResponse => Promise.resolve(workersResponse.data))
    .catch(err => Promise.reject(err))


export const getById = id => (dispatch, getState) => axios.get(`/workers/${id}`)
    .then(workersResponse => Promise.resolve(workersResponse.data))
    .catch(err => Promise.reject(err))

export const editById = (id, worker) => (dispatch, getState) => axios.put(`/workers/${id}`, worker)
    .then(workersResponse => Promise.resolve(workersResponse.data))
    .catch(err => Promise.reject(err))
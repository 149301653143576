import React, { Component } from 'react'
import validator from 'validator'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

class FormDialog extends Component {

    constructor(props) {
        super(props)
        this.state = this.generateState()
    }

    generateState() {
        let propsToState = {}
        this.props.fields.forEach(field => { propsToState[field.name] = field.value; propsToState[`${field.name}Error`] = false })
        return Object.assign({}, propsToState)
    }

    handleClose = () => {
        this.setState({ open: false })
        this.props.onClose()
    }

    onChange(event) {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleChangeSelectItem(items) {
        this.setState({ selectedItems: items })
    }

    saveChanges() {
        let allGood = true
        let stateCopy = { ...this.state }

        //reset prev error
        this.props.fields.forEach(element => stateCopy[`${element.name}Error`] = false)
        this.setState(stateCopy)

        if (this.props.validate) {
            this.props.fields.forEach((element, index) => {
                if (validator.isEmpty(this.state[element.name].trim())) {
                    stateCopy[`${element.name}Error`] = true
                    allGood = false
                }
            })
            if (!allGood) {
                this.setState(stateCopy)
                return;
            }
        }

        //generate output json
        let output = {}
        this.props.fields.forEach(element => output[element.name] = this.state[element.name])
        this.props.onSave({ type: this.props.type, fields: output })
    }

    renderFields() {
        return this.props.fields.map((field, index) => <TextField
            key={index}
            fullWidth={true}
            margin="dense"
            error={this.state[`${field.name}Error`]}
            value={this.state[field.name]}
            name={field.name}
            label={field.label}
            multiline={field.multiline ? true : false}
            type={field.type}
            onChange={event => this.onChange(event)}
        />)
    }

    render() {
        return (
            <div>
                <Dialog
                    open={this.props.open}
                    onClose={() => this.props.onCancel()}
                    aria-labelledby="form-dialog-title"
                    className={this.props.className}
                    fullWidth={true}
                    maxWidth={this.props.maxWidth}
                >
                    <DialogTitle id="form-dialog-title">{this.props.dialogTitle}</DialogTitle>
                    <DialogContent>
                        {this.props.dialogText ? <DialogContentText>
                            {this.props.dialogText}
                        </DialogContentText> : null}
                        {this.renderFields()}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary">
                            {this.props.cancelButtonText}
                        </Button>
                        <Button onClick={() => this.saveChanges()} color="primary">
                            {this.props.submitButtonText}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default FormDialog
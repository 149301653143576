import axios from '../../utils/axios'
import * as TYPES from '../actionTypes'

export const getAll = () => (dispatch, getState) => axios.get('/users')
    .then(personsResponse => Promise.resolve(personsResponse.data))
    .catch(err => Promise.reject(err))

export const getAccountByKey = key => (dispatch, getState) => axios.get(`/users/key/${key}`)
    .then(personsResponse => Promise.resolve(personsResponse.data))
    .catch(err => Promise.reject(err))

export const activateAccountByKey = (key, password) => (dispatch, getState) => axios.put(`/users/key/${key}`, { password })
    .then(personsResponse => {
        dispatch({
            type: TYPES.IS_LOGGED,
            payload: {
                isAdmin: personsResponse.data.isAdmin,
                _id: personsResponse.data._id
            }
        })
        return Promise.resolve(personsResponse.data)
    })
    .catch(err => Promise.reject(err))


export const resetPassword = userId => (dispatch, getState) => axios.put(`/users/${userId}/resetPassword`)
    .then(personsResponse => Promise.resolve(personsResponse.data))
    .catch(err => Promise.reject(err))

export const getProfile = () => (dispatch, getState) => axios.get('/users/profile')
    .then(personsResponse => Promise.resolve(personsResponse.data))
    .catch(err => Promise.reject(err))

export const saveProfile = profile => (dispatch, getState) => axios.put('/users/profile', { profile })
    .then(personsResponse => {
        dispatch({
            type: TYPES.CHANGE_FULL_NAME,
            payload: {
                fullName: profile.fullName
            }
        })
        return Promise.resolve(personsResponse.data)
    })
    .catch(err => Promise.reject(err))

export const create = user => (dispatch, getState) => axios.post('/users', { user })
    .then(personsResponse => Promise.resolve(personsResponse.data))
    .catch(err => Promise.reject(err))

export const deleteById = id => (dispatch, getState) => axios.delete(`/users/${id}`)
    .then(personsResponse => Promise.resolve(personsResponse.data))
    .catch(err => Promise.reject(err))

export const getById = id => (dispatch, getState) => axios.get(`/users/${id}`)
    .then(personsResponse => Promise.resolve(personsResponse.data))
    .catch(err => Promise.reject(err))

export const editById = (id, user) => (dispatch, getState) => axios.put(`/users/${id}`, { user })
    .then(personsResponse => Promise.resolve(personsResponse.data))
    .catch(err => Promise.reject(err))
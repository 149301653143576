import * as TYPES from '../actionTypes'

const initialState = {
  isLogged: false,
  isAdmin: true,
  userId: null,
  username: '',
  fullName: ''
}

function loginReducer(state = initialState, action) {
  switch (action.type) {
    case TYPES.IS_LOGGED:
      return { ...state, isLogged: true, isAdmin: action.payload.isAdmin, userId: action.payload._id, username: action.payload.username, fullName: action.payload.fullName}
    case TYPES.IS_NOT_LOGGED:
      return { ...state, isLogged: false }
    case TYPES.CHANGE_FULL_NAME: {
        return { ...state, fullName: action.payload.fullName }
    }
    default:
      return state
  }
}

export default loginReducer
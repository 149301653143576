import axios from '../../utils/axios'

export const get = (startDate, endDate, filter) => () => axios.get(startDate && endDate ? `/history?fromDate=${startDate}&endDate=${endDate}&filter=${filter}` : `/history?filter=${filter}`)
    .then(daysResponse => Promise.resolve(daysResponse.data))
    .catch(err => Promise.reject(err))

export const countByDayAndInterval = (dayId, intervalIndex) => () => axios.get(`/history/day/${dayId}/${intervalIndex}`)
    .then(daysResponse => Promise.resolve(daysResponse.data))
    .catch(err => Promise.reject(err))

export const getByUserId = userId => () => axios.get(`/history/user/${userId}`)
    .then(daysResponse => Promise.resolve(daysResponse.data))
    .catch(err => Promise.reject(err))

export const updateInvoiced = (userId, invoicedList) => () => axios.put(`/history/user/${userId}`, { invoicedList })
    .then(daysResponse => Promise.resolve(daysResponse.data))
    .catch(err => Promise.reject(err))
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'
import Users from './users/Users'
import Days from './days/Days'
import Reports from './reports/Reports'
import Settings from './settings/Settings'
import * as LOGIN from '../../redux/actions/login'
import * as NAVIGATION from '../../redux/actions/navigation'

class Admin extends Component {

    componentDidMount() {
        if (this.props.loginState.isAdmin) {
            this.props.showNavigation()
            this.setState({ isAdmin: true, renderPage: true })
        }
        else
            this.setState({ isAdmin: false, renderPage: false })
    }

    changeIsLogged(isLogged) {
        this.setState({ isLogged: isLogged })
    }

    state = {
        renderPage: false,
        isAdmin: false
    }

    render() {
        if (this.state.renderPage && this.state.isAdmin) {
            return (
                <div className='paddingTopContent'>
                    <Switch>
                        <Route path={`${this.props.match.path}/`} exact component={Users} />
                        <Route path={`${this.props.match.path}/days`} exact component={Days} />
                        <Route path={`${this.props.match.path}/reports`} exact component={Reports} />
                        <Route path={`${this.props.match.path}/settings`} exact component={Settings} />
                    </Switch>
                </div>
            )
        } else return null
    }
}

const mapStateToProps = state => ({
    loginState: state.loginReducer
})

const mapDispatchToProps = dispatch => {
    return {
        isLogged: () => dispatch(LOGIN.isLogged()),
        showNavigation: () => dispatch(NAVIGATION.show())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Admin)
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody, Button, TextField, withStyles } from '@material-ui/core'
import SimpleModal from '../common/SimpleModal'
import * as DAYS from '../../redux/actions/days'
import * as NAVIGATION from '../../redux/actions/navigation'
import * as NOTIFICATION from '../../utils/notification'

const styles = () => ({
    free: {
        backgroundColor: 'rgb(6, 215, 156) !important'
    },
    reserved: {
        backgroundColor: 'rgb(116, 90, 242) !important'
    },
    busy: {
        backgroundColor: 'rgb(252, 75, 108) !important'
    },
    timeInterval: {
        width: '60px !important',
        paddingRight: '10px',
        textAlign: 'center',
        '@media (max-width: 480px)': {
            fontSize: '0.75em',
            width: '50px !important'
        }
    },
    table: {
        borderCollapse: 'separate',
        borderSpacing: '5px 5px',
        '& td': {
            width: '16%',
            padding: '5px 5px 5px 5px !important',
            cursor: 'pointer',
            backgroundColor: '#ffffff',
            '@media (max-width: 480px)': {
                fontSize: '0.75em',
                paddingTop: '0px !important',
                paddingBottom: '0px !important'
            }
        },
        '& th': {
            width: '16%',
            padding: '5px 5px 5px 5px !important',
            textAlign: 'center',
            fontSize: '1em',
            '@media (max-width: 480px)': {
                fontSize: '0.75em'
            }
        },
        '& tr': {
            height: '40px',
            '@media (max-width: 480px)': {
                height: '15px'
            }
        }
    },
    weekController: {
        width: '300px',
        margin: '20px auto 20px',
        display: 'flex',
        flexDirection: 'row',
        '& button': {
            fontSize: '1.5em',
            color: 'rgba(0, 0, 0, 0.54)'
        }
    },
    currentMonth: {
        textTransform: 'capitalize',
        width: '200px',
        textAlign: 'center',
        fontSize: '1.5em',
        color: 'rgba(0, 0, 0, 0.54)',
        marginTop: '4px',
        '@media (max-width: 480px)': {
            width: '150px',
            fontSize: '1.25em'
        }
    },
    dayDate: {
        display: 'block',
        fontSize: '0.85em',
        marginTop: '5px'
    },
    reasonToCancelField: {
        width: '100%'
    },
    legend: {
        width: '100%',
        display: 'flex',
        paddingTop: '20px',
        flexDirection: 'column'
    },
    legendStatusSample: {
        '& span': {
            content: '""',
            display: 'inline-block',
            width: '20px',
            height: '20px',
            borderRadius: '50%',
            position: 'relative',
            marginRight: '5px',
            marginBottom: '-4px'
        },
        fontSize: '0.75em',
        paddingLeft: '20px',
        width: '30%',
        '@media (max-width: 480px)': {
            paddingLeft: '5px'
        }
    },
    statusContainer: {
        display: 'flex',
        width: '100%',
        margin: 'auto',
        '@media (max-width: 480px)': {
            fontSize: '0.75em !important'
        },
        '@media (min-width: 680px)': {
            width: '500px'
        }
    },
    legendText: {
        width: '100%',
        textAlign: 'center',
        marginTop: '20px',
        fontSize: '0.75em',
        '@media (max-width: 670px)': {
            fontSize: '10px'
        }
    },
    noBg: {
        backgroundColor: '#ffffff !important',
        fontSize: '0.75em'
    },
    container: {
        width: '90%',
        margin: 'auto',
        padding: '20px',
        boxSizing: 'border-box',
        '@media (max-width: 480px)': {
            width: '100%',
            padding: '5px'
        }
    },
    gray: {
        backgroundColor: '#e6e6e6 !important'
    }
})

const defaultIntervals = [
    { key: "9-10", from: 9, to: 10 },
    { key: "10-11", from: 10, to: 11 },
    { key: "11-12", from: 11, to: 12 },
    { key: "12-13", from: 12, to: 13 },
    { key: "13-14", from: 13, to: 14 },
    { key: "14-15", from: 14, to: 15 },
    { key: "15-16", from: 15, to: 16 },
    { key: "16-17", from: 16, to: 17 },
    { key: "17-18", from: 17, to: 18 },
    { key: "18-19", from: 18, to: 19 },
    { key: "19-20", from: 19, to: 20 },
    { key: "20-21", from: 20, to: 21 }
]

class Home extends Component {

    constructor(props) {
        super(props)
        this.intervalId = null
        this.selectedDate = null
        this.intervalKey = null
    }

    state = {
        services: [],
        modalAcceptButtonText: '',
        modalCancelButtonText: null,
        openModal: false,
        modalText: '',
        modalTitle: '',
        modalType: 'free',
        reasonToCancel: '',
        reservedDays: [],
        weekDays: [],
        mergedWeekDays: [],
        renderPage: false,
        startWeek: moment().startOf('week'),
        endWeek: moment().endOf('week')
    }

    componentDidMount() {
        this.props.hideNavigation()
        this.loadDays()
    }

    openModal = interval => {
        this.intervalId = interval._id
        this.selectedDate = moment(interval.dateTime).toISOString()
        this.intervalKey = interval.key
        let intervalSplit = interval.key.split('-')
        let newInterval = `${intervalSplit[0]}-${Number(intervalSplit[1])}`
        switch (interval.status) {
            case 'free':
                this.setState({
                    openModal: true,
                    modalType: 'free',
                    modalText: `Doriti rezervarea unui scaner intraoral pentru data de ${moment(interval.dateTime).format('DD.MM.YYYY')} in intervalul orar ${newInterval}?`,
                    modalTitle: 'Rezervare scanner',
                    modalAcceptButtonText: 'DA',
                    modalCancelButtonText: 'NU'
                })
                break
            case 'reserved':
                this.setState({
                    openModal: true,
                    modalType: 'reserved',
                    reasonToCancel: '',
                    modalText: `Doriti anularea rezervarii dvs. din data de ${moment(interval.dateTime).format('DD.MM.YYYY')} pentru intervalul orar ${newInterval}?`,
                    modalTitle: 'Anulare rezervare scanner',
                    modalAcceptButtonText: 'DA',
                    modalCancelButtonText: 'NU'
                })
                break
            case 'busy':
                this.setState({
                    openModal: true,
                    modalType: 'busy',
                    modalText: `Din pacate intervalul orar ${newInterval} din data ${moment(interval.dateTime).format('DD.MM.YYYY')} nu mai este disponibil. Va rugam alegeti alta perioada de timp.`,
                    modalTitle: 'Interval indisponibil',
                    modalAcceptButtonText: 'OK',
                    modalCancelButtonText: null
                })
            default:
                return null
        }
    }

    acceptModal = () => {
        if (this.state.modalType === 'free') {
            return this.props.rent(this.selectedDate, this.intervalId, { action: 'rent' }).then(() => {
                NOTIFICATION.success("Aparatul a fost inchiriat")
                this.loadDays()
            }).catch(() => {
                NOTIFICATION.error("Aparatul nu a putut fi inchiriat")
                this.loadDays()
            })
        } else {
            if (this.state.modalType === 'reserved') {
                return this.props.getSettingsHour().then(hour => {
                    let duration = moment.duration(moment(this.selectedDate).startOf('day').add(Number(this.intervalKey.split('-')[0]), 'hours').diff(moment()))
                    if(Number(hour) >= duration.asHours()){
                        this.setState({openModal: false})
                        return NOTIFICATION.error(`Nu se poate anula o rezervare cu ${Number(hour) === 1 ? 'o ora' : `${hour} ore`} inainte de inchiriere`)
                    }
                    return this.props.cancel(this.selectedDate, this.intervalId, { action: 'cancel', reason: this.state.reasonToCancel }).then(() => {
                        NOTIFICATION.success("Rezervarea a fost anulata")
                        this.loadDays()
                    }).catch(() => {
                        NOTIFICATION.error("Nu s-a putut anula rezervarea")
                        this.loadDays()
                    })
                })
            } else {
                this.setState({ openModal: false })
            }
        }
    }

    goBackWeek = () => {
        this.setState({
            endWeek: this.state.startWeek.clone(),
            startWeek: this.state.startWeek.clone().subtract(7, 'days')
        }, this.loadDays)
    }

    goNextWeek = () => {
        this.setState({
            endWeek: this.state.endWeek.clone().add(7, 'days'),
            startWeek: this.state.endWeek.clone()
        }, this.loadDays)
    }

    loadDays = () => this.props.getDays(this.state.startWeek.toISOString(), this.state.endWeek.toISOString()).then(
        daysResponse => this.setState({
            reservedDays: daysResponse,
            openModal: false
        }, () => this.mergeDays())
    )

    mergeDays = () => {
        let dates = {}
        let index = 0
        let weekDays = []

        for (let m = this.state.startWeek.clone(); m.isBefore(this.state.endWeek); m.add(1, 'days')) {

            //skip sunday
            if (m.day() === 0) continue
            weekDays.push(m.clone())
            dates[index] = { intervals: [...defaultIntervals.map(a => a)], dateTime: m.clone().toISOString() }

            this.state.reservedDays.forEach(day => {
                if (moment(day.dateTime).isSame(m.toISOString(), 'day') && moment(day.dateTime).isSame(m.toISOString(), 'year')) {
                    let intervalFromMap = {}

                    day.intervals.forEach(reserve => intervalFromMap[reserve.key.split('-')[0]] = reserve)
                    for (let intervalIndex = 0; dates[index].intervals[intervalIndex]; intervalIndex++) {
                        let interval = dates[index].intervals[intervalIndex]
                        if (intervalFromMap[interval.from]) {
                            dates[index].intervals[intervalIndex] = {
                                ...intervalFromMap[interval.from],
                                from: Number(intervalFromMap[interval.from].key.split('-')[0]),
                                to: Number(intervalFromMap[interval.from].key.split('-')[1]),
                                rentedDevices: intervalFromMap[interval.from].rentedDevices,
                                dateTime: m.clone().toISOString(),
                                isBefore: m.isBefore(moment().startOf('day')),
                                devicesNr: intervalFromMap[interval.from].devicesNr,
                                status: this.parseStatus(intervalFromMap[interval.from]),
                                _id: intervalFromMap[interval.from]._id,
                                addOne: true
                            }
                            let skip = dates[index].intervals[intervalIndex].to - dates[index].intervals[intervalIndex].from - 1
                            dates[index].intervals.splice(intervalIndex + 1, skip)
                        }
                    }
                }

            })

            index++;
        }
        //columns 
        let columns = {}
        defaultIntervals.forEach(interval => {
            columns[interval.from] = { intervals: [], key: interval.key }
            for (let column = 0; column < 6; column++) {
                dates[column].intervals.forEach(searchInterval => {
                    if (searchInterval.from === interval.from)
                        columns[interval.from].intervals.push({ ...searchInterval, dateTime: dates[column].dateTime })
                })
            }
        })
        this.setState({ mergedWeekDays: Object.values(columns), renderPage: true, weekDays })
    }

    handleChange = event => this.setState({ [event.target.name]: event.target.value })

    parseStatus = interval => {
        if (!interval._id) return ''
        if (interval.devicesNr === 0) return 'busy'
        else {
            if (interval.rentUsers.indexOf(this.props.userId) > -1) return 'reserved'
            else
                if (interval.devicesNr === interval.rentedDevices) return 'busy'
                else {
                    if (interval.devicesNr > interval.rentedDevices) return 'free'
                }
        }
    }

    render() {
        const { classes } = this.props
        const { i18n } = this.props.languageState
        return (
            <Paper className={classes.container}>
                <div className={classes.weekController}>
                    <Button onClick={this.goBackWeek}> {'<'} </Button>
                    <Typography className={classes.currentMonth} component="h2">{this.state.startWeek.format('MMMM  YYYY')}</Typography>
                    <Button onClick={this.goNextWeek}> {'>'} </Button>
                </div>
                <SimpleModal
                    open={this.state.openModal}
                    cancelButtonText={this.state.modalCancelButtonText}
                    acceptButtonText={this.state.modalAcceptButtonText}
                    size='xs'
                    title={this.state.modalTitle}
                    onCancel={() => this.setState({ openModal: false })}
                    onClose={() => this.setState({ openModal: false })}
                    onAccept={this.acceptModal}
                >
                    {this.state.modalText}
                    {this.state.modalType === 'reserved' && <TextField
                        label={i18n.reason}
                        multiline
                        name='reasonToCancel'
                        className={classes.reasonToCancelField}
                        value={this.state.reasonToCancel}
                        onChange={this.handleChange}
                        margin='normal'
                    />}
                </SimpleModal>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.timeInterval}>Ora</TableCell>
                            {this.state.weekDays.map(day => <TableCell component="th" align="center"><div>{day.format('dd')}</div><div className={classes.dayDate}>{day.format('DD.MM')}</div></TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.mergedWeekDays.map(day =>
                            <TableRow>
                                <TableCell className={`${classes.timeInterval} ${classes.noBg}`} component="td" scope="row">{day.key.split('-')[0]}</TableCell>
                                {day.intervals.map(interval =>
                                    <TableCell onClick={!interval.isBefore ? () => this.openModal(interval) : () => { }} component="td" scope="row" rowSpan={interval.to - interval.from} className={interval.isBefore && interval.status !== 'reserved' ? classes.gray : classes[interval.status]}></TableCell>
                                )}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
                <div className={classes.legend}>
                    <div className={classes.statusContainer}>
                        <div className={classes.legendStatusSample} >
                            <span style={{ backgroundColor: 'rgb(6, 215, 156)' }}></span> {i18n.free}
                        </div>
                        <div className={classes.legendStatusSample}>
                            <span style={{ backgroundColor: 'rgb(252, 75, 108)' }}></span> {i18n.busy}
                        </div>
                        <div className={classes.legendStatusSample}>
                            <span style={{ backgroundColor: 'rgb(116, 90, 242)' }}></span> {i18n.reserved}
                        </div>
                    </div>
                    <Typography className={classes.legendText}>
                        {i18n.footer.firstLine}<br />
                        {i18n.footer.secondLine}
                    </Typography>
                </div>
            </Paper >
        )
    }
}

const mapStateToProps = state => ({
    loginState: state.loginReducer,
    languageState: state.languageReducer
})

const mapDispatchToProps = dispatch => ({
    getSettingsHour: () => dispatch(DAYS.getSettingsHour()),
    getDays: (startFrom, endTo) => dispatch(DAYS.get(startFrom, endTo)),
    hideNavigation: () => dispatch(NAVIGATION.show(false)),
    rent: (date, intervalId, object) => dispatch(DAYS.rent(date, intervalId, object)),
    cancel: (date, intervalId, object) => dispatch(DAYS.cancel(date, intervalId, object))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Home))
import React, { Component } from 'react'
import { JssProvider } from 'react-jss'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import queryString from 'query-string'
import Login from './login/Login'
import ResetPassword from './login/ResetPassword'
import Home from './home/Home'
import Topbar from './common/Topbar'
import Admin from './admin/Admin'
import * as LOGIN from '../redux/actions/login'
import * as NAVIGATION from '../redux/actions/navigation'
import SimpleModal from '../components/common/SimpleModal'
import * as NOTIFICATION from '../utils/notification'

class Root extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isLogged: true,
            renderPage: false,
            showSidebar: true,
            userId: null,
            acceptedTerms: false
        }
    }

    componentWillMount() {
        this.props.isLogged()
            .then(response => {
                this.setState({ isLogged: true, renderPage: true, userId: response._id, acceptedTerms: response.acceptedTerms })
            })
            .catch(() => this.setState({ isLogged: false, renderPage: true }))
    }

    changeIsLogged(isLogged, userId, acceptedTerms) {
        this.setState({ isLogged: isLogged, userId: userId, acceptedTerms })
    }

    acceptTerms = () => {
        this.props.acceptTerms().then(() => {
            NOTIFICATION.success("Termenii si conditiile au fost acceptate")
            this.setState({acceptedTerms: true})
        }).catch(() => {
            NOTIFICATION.success("A aparut o eroare")
        })
    }

    render() {
        const LoginRouter = props => {
            let route = window.location.href
            if (route.search('\\?') > -1) {
                let query = queryString.parse(route.split('?')[1])
                if (query.user)
                    return <ResetPassword isLogged={props.isLogged} userKey={query.user} />
            }
            return <Login isLogged={props.isLogged} />
        }

        if (this.state.renderPage) {
            return (<JssProvider>
                <Router>
                    {this.state.isLogged ? (
                        <div className='fullHeight' style={{ display: 'flex' }}>
                            <Topbar isLogged={isLogged => this.changeIsLogged(isLogged)} sidebarOpen={this.state.showSidebar} toggleSidebar={() => this.toggleSidebar()} />
                            <SimpleModal
                                title=""
                                open={!this.state.acceptedTerms}
                                acceptButtonText="Accept"
                                onAccept={this.acceptTerms}
                                onClose={() => { }}
                                fullScreen={true}
                            >
                            Accesarea acestui website este supusa unor termeni si conditii prezentate mai jos: <br/><br />
                            <iframe style={{border:'1px solid #e0e0e0', width:'100%', height: '80vh'}}  title="termeni" src="http://scanareorala.ro/termeni_iframe.html"></iframe>
                          
                            </SimpleModal>
                            <div className={`contentContainer`}>
                                <div className='baseContainer'>
                                    <Route path="/" exact render={props => <Home {...props} userId={this.state.userId} />} />
                                    <Route path="/admin" component={Admin} />
                                    <Route path="/reload" exact component={null} />
                                </div>
                            </div>
                        </div>
                    ) : <LoginRouter isLogged={(isLogged, userId, acceptedTerms) => this.changeIsLogged(isLogged, userId, acceptedTerms)} />}
                </Router>
            </JssProvider>)
        } else return null
    }
}

const mapStateToProps = state => ({
    loginState: state.loginReducer
})

const mapDispatchToProps = dispatch => {
    return {
        isLogged: () => dispatch(LOGIN.isLogged()),
        changeNavigationTab: activeTab => dispatch(NAVIGATION.changeTab(activeTab)),
        acceptTerms: () => dispatch(LOGIN.acceptTerms())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Root)
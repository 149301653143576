import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import ReactToPrint from 'react-to-print'
import { Paper, Button, IconButton, Typography, TextField, FormControl, Select, MenuItem, Table, TableBody, TableHead, TableCell, TableRow, withStyles } from '@material-ui/core'
import { Done as DoneIcon, Print as PrintIcon } from '@material-ui/icons'
import * as HISTORY from '../../../redux/actions/history'
import SimpleModal from '../../common/SimpleModal'
import Printer from '../../common/Printer'
import * as NAVIGATION from '../../../redux/actions/navigation'

const styles = () => ({
    reportRow: {
        display: 'flex',
        width: '100%',
        padding: '10px 30px 10px 30px',
        borderTop: '1px solid #e0e0e0',
        boxSizing: 'border-box',
        height: 'auto'
    },
    dateTime: {
        display: 'block',
        width: '200px',
        fontWeight: '1.25em',
        '&:before': {
            content: "''",
            display: 'inline-block',
            height: '100%',
            verticalAlign: 'middle',
            marginRight: '-0.25em'
        }
    },
    interval: {
        marginTop: '20px',
        '& > span': {
            padding: '10px',
            fontSize: '1em',
            fontWeight: 'bold'
        }
    },
    report: {
        width: 'calc(100% - 220px)'
    },
    action: {
        marginLeft: '30px',
        paddingTop: '15px',
        borderBottom: '1px solid #e9e9e9',
        paddingBottom: '5px',
        '& span.name': {
            width: '25%',
            display: 'inline-block'
        },
        '& span.address': {
            width: '60%',
            display: 'inline-block'
        },
        '& span.status': {
            width: '15%',
            display: 'inline-block',
            textTransform: 'capitalize',
            textAlign: 'right'
        }
    },
    finalizata: {
        color: 'rgb(6, 215, 156)'
    },
    anulata: {
        color: 'rgb(252, 75, 108)'
    },
    urmeaza: {

    },
    date: {
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        '& button': {
            fontSize: '1em',
            color: 'rgba(0, 0, 0, 0.54)',
            minWidth: '50px',
            paddingRight: '0px',
            paddingLeft: '0px'
        }
    },
    timeInterval: {
        display: 'flex',
        paddingRight: '10px',
        textAlign: 'center',
        minHeight: '50px',
        lineHeight: '50px'
    },
    highContent: {
        width: 'calc(100% - 200px)'
    },
    menu: {
        width: '200px',
        borderLeft: '1px solid #e0e0e0',
        '& button': {
            width: '100%',
            padding: '10px'
        }
    },
    currentMonth: {
        textAlign: 'center',
        fontSize: '1.25em',
        textTransform: 'capitalize',
        color: 'rgba(0, 0, 0, 0.54)',
        marginTop: '4px'
    },
    textField: {
        width: '100%'
    },
    selectBox: {
        display: 'flex',
        marginLeft: 'auto',
        marginRight: '10px',
        width: '150px'
    }
})

class Reports extends Component {

    state = {
        reports: [],
        render: false,
        startOfMonth: moment().startOf('month'),
        endOfMonth: moment().endOf('month'),
        view: 'month',
        dayReportValue: moment().format('YYYY-MM-DD'),
        printReportValue: moment().format('YYYY-MM-DD'),
        openOtherModal: false,
        openDayModal: false,
        filter: 'all',
        openPrint: false,
        openPrintModal: false,
        openPrintPage: false,
        printerList: []
    }

    componentDidMount() {
        this.props.changeTab(2)
        this.loadReports()
    }

    loadReports = () => this.props.getHistory(this.state.startOfMonth.toISOString(), this.state.endOfMonth.toISOString(), this.state.filter).then(historyResponse => {
        let reports = JSON.parse(JSON.stringify(historyResponse))
        let reportsOrder = {}
        reports.forEach(report => {
            report.isBefore = moment(report.dateTime).isBefore(moment().startOf('day'))
            if (reportsOrder[report.dateTime] && reportsOrder[report.dateTime][report.interval]) {
                reportsOrder[report.dateTime][report.interval].push(report)
            } else {
                if (!reportsOrder[report.dateTime])
                    reportsOrder[report.dateTime] = {}
                if (!reportsOrder[report.dateTime][report.interval])
                    reportsOrder[report.dateTime][report.interval] = []

                reportsOrder[report.dateTime][report.interval].push(report)
            }
        })

        //sort
        let newList = {}
        let newKeys = Object.keys(reportsOrder).sort()
        newKeys.forEach(key => {
            newList[key] = { dateTime: key, intervals: {} }
            Object.keys(reportsOrder[key]).sort(function (a, b) {
                let splitA = a.split('-')
                let splitB = b.split('-')
                return Number(splitA[0]) - Number(splitB[0])
            }).forEach(intervalKey => {

                // if (newList[key].intervals[intervalKey.interval]) {
                //     newList[key].intervals[intervalKey].push(reportsOrder[key][intervalKey])
                // } else {
                //     newList[key].intervals[intervalKey] = []
                //     newList[key].intervals[intervalKey].push(reportsOrder[key][intervalKey])
                // }
                newList[key].intervals[intervalKey] = reportsOrder[key][intervalKey]
            })
        })

        this.setState({
            reports: Object.values(newList),
            render: true
        })
    })

    goNextMonth = () => {
        let newMonthStart = this.state.startOfMonth.clone().add(1, 'months')
        let newMonthEnd = this.state.endOfMonth.clone().add(1, 'month')
        this.setState({
            startOfMonth: newMonthStart.startOf('month'),
            endOfMonth: newMonthEnd.endOf('month')
        }, () => this.loadReports())
    }

    goPrevMonth = () => {
        let newMonthStart = this.state.startOfMonth.clone().subtract(1, 'months')
        let newMonthEnd = this.state.endOfMonth.clone().subtract(1, 'month')
        this.setState({
            startOfMonth: newMonthStart.startOf('month'),
            endOfMonth: newMonthEnd.endOf('month')
        }, () => this.loadReports())
    }

    goNextDay = () => {
        let nextDay = moment(this.state.dayReportValue).clone().add(1, 'day')
        let newDayStart = nextDay.clone().startOf('day')
        let newDayEnd = nextDay.clone().endOf('day')
        this.setState({
            startOfMonth: newDayStart,
            endOfMonth: newDayEnd,
            dayReportValue: nextDay.format('YYYY-MM-DD')
        }, () => this.loadReports())
    }

    goPrevDay = () => {
        let prevDay = moment(this.state.dayReportValue).clone().subtract(1, 'day')
        let newDayStart = prevDay.clone().startOf('day')
        let newDayEnd = prevDay.clone().endOf('day')
        this.setState({
            startOfMonth: newDayStart,
            endOfMonth: newDayEnd,
            dayReportValue: prevDay.format('YYYY-MM-DD')
        }, () => this.loadReports())
    }


    changeMenu = type => {
        if (type === 'month') {
            this.setState({
                startOfMonth: moment().startOf('month'),
                endOfMonth: moment().endOf('month'),
                view: 'month'
            }, () => this.loadReports())
        }

        if (type === 'day') {
            if (moment(this.state.dayReportValue).isValid()) {
                this.setState({
                    startOfMonth: moment(this.state.dayReportValue).clone().startOf('day'),
                    endOfMonth: moment(this.state.dayReportValue).clone().endOf('day'),
                    view: 'day',
                    openDayModal: false
                }, () => this.loadReports())
            }
        }

        if (type === 'other') {
            if (moment(this.state.dayReportValue).isValid()) {
                this.setState({
                    view: 'other',
                    openDayModal: false,
                    openOtherModal: false
                }, () => this.loadReports())
            }
        }

        if (type === 'print') {
            if (moment(this.state.printReportValue).isValid()) {
                this.props.getHistory(moment(this.state.printReportValue).startOf('day').toISOString(), moment(this.state.printReportValue).endOf('day').toISOString(), 'urmeaza').then(response => {
                    //sort
                    response.sort(function (a, b) {
                        let splitA = a.interval.split('-')
                        let splitB = b.interval.split('-')
                        return Number(splitA[0]) - Number(splitB[0])
                    })
                    this.setState({ openPrintPage: true, openPrintModal: false, printerList: response })
                })
            }
        }
    }

    render() {
        const { classes } = this.props
        return this.state.render ? (<Paper style={{ display: 'flex' }}>
            <Printer
                header={<>
                    <Typography className={this.props.classes.headerText}> REZERVARI {moment(this.state.printReportValue).format('DD.MM.YYYY')}</Typography>
                </>}
                open={this.state.openPrintPage}
                onClose={() => this.setState({ openPrintPage: false, printerList: [] })}
            >
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '30px' }} align="center">#</TableCell>
                            <TableCell align="center">Ora</TableCell>
                            <TableCell align="center">Nume</TableCell>
                            <TableCell align="center">Adresa</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.printerList.map((report, index) =>
                            <TableRow>
                                <TableCell align="center" component="td" scope="row">{index + 1}</TableCell>
                                <TableCell align="center">{report.interval}</TableCell>
                                <TableCell align="center">{report.user.fullName}<br />{report.user.office}</TableCell>
                                <TableCell align="center">{report.user.address}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </Printer>
            <SimpleModal
                open={this.state.openDayModal}
                onCancel={() => this.setState({ openDayModal: false })}
                onClose={() => this.setState({ openDayModal: false })}
                onAccept={() => this.changeMenu('day')}
                cancelButtonText='Anuleaza'
                acceptButtonText='Afiseaza Raport'
                size='xs'
                title='Selectati ziua'
            >
                <TextField
                    id="date"
                    className={classes.textField}
                    label="Ziua"
                    type="date"
                    onChange={event => this.setState({ dayReportValue: event.target.value })}
                    value={this.state.dayReportValue}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </SimpleModal>
            <SimpleModal
                open={this.state.openPrintModal}
                onCancel={() => this.setState({ openPrintModal: false })}
                onClose={() => this.setState({ openPrintModal: false })}
                onAccept={() => this.changeMenu('print')}
                cancelButtonText='Anuleaza'
                acceptButtonText='Afiseaza Raport zi'
                size='xs'
                title='Selectati ziua'
            >
                <TextField
                    id="date"
                    className={classes.textField}
                    label="Ziua"
                    type="date"
                    onChange={event => this.setState({ printReportValue: event.target.value })}
                    value={this.state.printReportValue}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </SimpleModal>
            <SimpleModal
                open={this.state.openOtherModal}
                onCancel={() => this.setState({ openOtherModal: false })}
                onClose={() => this.setState({ openOtherModal: false })}
                onAccept={() => this.changeMenu('other')}
                cancelButtonText={this.props.languageState.i18n.cancel}
                acceptButtonText={this.props.languageState.i18n.view}
                size='xs'
                title={this.props.languageState.i18n.selectInterval}
            >
                <TextField
                    id="date"
                    className={classes.textField}
                    label={this.props.languageState.i18n.fromDate}
                    type="date"
                    onChange={event => this.setState({ startOfMonth: moment(event.target.value) })}
                    value={this.state.startOfMonth.format('YYYY-MM-DD')}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
                <TextField
                    id="date"
                    className={classes.textField}
                    label={this.props.languageState.i18n.toDate}
                    type="date"
                    onChange={event => this.setState({ endOfMonth: moment(event.target.value) })}
                    value={this.state.endOfMonth.format('YYYY-MM-DD')}
                    InputLabelProps={{
                        shrink: true
                    }}
                />
            </SimpleModal>
            <div className={`${classes.highContent} reportsPrinter`} ref={ref => this.printElement = ref}>
                <div className={classes.timeInterval}>
                    {this.state.view === 'month' && <div className={classes.date}>
                        <Button onClick={this.goPrevMonth}> {'<'} </Button>
                        <Typography className={classes.currentMonth} component="h2">{this.state.startOfMonth.format('MMMM YYYY')}</Typography>
                        <Button onClick={this.goNextMonth}> {'>'} </Button>
                    </div>}
                    {this.state.view === 'day' && <div className={classes.date}>
                        <Button onClick={this.goPrevDay}> {'<'} </Button>
                        <Typography className={classes.currentMonth} component="h2">{moment(this.state.dayReportValue).format('DD.MM.YYYY')}</Typography>
                        <Button onClick={this.goNextDay}> {'>'} </Button>
                    </div>}
                    {this.state.view === 'other' && <div className={classes.date}>
                        <Typography style={{ marginLeft: '20px' }} className={classes.currentMonth} component="h2">{this.props.languageState.i18n.fromDate} {moment(this.state.startOfMonth).format('DD.MM.YYYY')} {this.props.languageState.i18n.toDate} {moment(this.state.endOfMonth).format('DD.MM.YYYY')}</Typography>
                    </div>}
                    <div className={classes.selectBox}>
                        <FormControl style={{ width: '100%', textAlign: 'left' }}>
                            <Select
                                value={this.state.filter}
                                onChange={event => this.setState({ filter: event.target.value }, () => this.loadReports())}
                                style={{ width: '100%', display: 'block', marginTop: '10px' }}
                            >
                                <MenuItem value='all'>
                                    <em>{this.props.languageState.i18n.reports.all}</em>
                                </MenuItem>
                                <MenuItem value={'urmeaza'}>{this.props.languageState.i18n.reports.inProgress}</MenuItem>
                                <MenuItem value={'anulata'}>{this.props.languageState.i18n.reports.canceled}</MenuItem>
                                <MenuItem value={'finalizata'}>{this.props.languageState.i18n.reports.finished}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <ReactToPrint
                        trigger={() => <IconButton style={{width: '56px'}} color="secondary"><PrintIcon /></IconButton>}
                        content={() => this.printElement} />
                </div>
                {this.state.reports.map(report => <div className={`${classes.reportRow} reportRowPrinter`}>
                    <div className={`${classes.dateTime} reportsDateTimePrinter`}>
                        <span>{moment(report.dateTime).format('ddd DD.MM.YYYY')}</span>
                    </div>
                    <div className={`${classes.report} reportContentPrinter`}>
                        {Object.keys(report.intervals).map(interval => <div className={`${classes.interval} intervalPrinter`}>
                            <span>{interval}</span>
                            {report.intervals[interval].map(action => <div className={`${classes.action} reportActionPrinter`}>
                                <span className='name'>{action.user.fullName}</span>
                                <span className='address'>{action.user.office}</span>
                                <span className={`status ${classes[action.status]}`}>{action.isBefore && action.status === 'urmeaza' ? this.props.languageState.i18n.reports.finished : action.status} {action.invoiced ? <DoneIcon style={{ marginBottom: '-5px', marginLeft: '5px' }} color='primary' /> : <span style={{ display: 'inline-block', width: '30px' }}></span>}</span>
                                <div style={{ width: '100%', textAlign: 'right', paddingTop: '5px', paddingRight: '33px', boxSizing: 'border-box', fontSize: '13px' }}>{action.reason}</div>
                            </div>)}
                        </div>)}
                    </div>
                </div>
                )}
            </div>
            <div className={classes.menu}>
                <Button onClick={() => this.changeMenu('month')} variant={this.state.view === 'month' && 'contained'} color='primary'>{this.props.languageState.i18n.reports.monthlyReport}</Button>
                <Button onClick={() => this.setState({ openDayModal: true })} variant={this.state.view === 'day' && 'contained'} color='primary'>{this.props.languageState.i18n.reports.dailyReport}</Button>
                <Button onClick={() => this.setState({ openOtherModal: true })} variant={this.state.view === 'other' && 'contained'} color='primary'>{this.props.languageState.i18n.reports.otherReports}</Button>
                <Button onClick={() => this.setState({ openPrintModal: true })} variant={this.state.view === 'print' && 'contained'} color='primary'>{this.props.languageState.i18n.reports.print}</Button>
            </div>
        </Paper >) : null

    }
}

const mapStateToProps = state => ({
    languageState: state.languageReducer
})

const mapDispatchToProps = dispatch => ({
    changeTab: tab => dispatch(NAVIGATION.changeTab(tab)),
    getHistory: (start, end, filter) => dispatch(HISTORY.get(start, end, filter))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Reports))
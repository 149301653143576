import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogContent, DialogActions, Button, DialogContentText } from '@material-ui/core'

class ConfirmationDialog extends Component {

    render() {
        return (
            <Dialog
                open={this.props.open}
                fullWidth={true}
                onClose={() => this.props.onClose()}
                maxWidth={'xs'}
            >
                <DialogContent>
                    <DialogContentText style={{textAlign: 'center'}}>
                        {this.props.text}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onCancel()} color="primary">
                        {this.props.cancelButtonText}
                    </Button>
                    <Button onClick={() => this.props.onAccept()} color="primary">
                        {this.props.acceptButtonText}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ConfirmationDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    cancelButtonText: PropTypes.string.isRequired,
    acceptButtonText: PropTypes.string.isRequired,
    onAccept: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired
}

export default ConfirmationDialog
import * as TYPES from '../actionTypes'

const initialState = {
    activeTab: 0,
    show: false
}

function navigationReducer(state = initialState, action) {
    switch (action.type) {
        case TYPES.NAVIGATION_CHANGE_ACTIVE_TAB:
            return { ...state, activeTab: action.payload }
        case TYPES.NAVIGATION_SHOW:
            return { ...state, show: action.payload}
        default:
            return state
    }
}

export default navigationReducer
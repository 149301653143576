import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Paper, TextField, Typography, Checkbox, Button, FormControlLabel, withStyles } from '@material-ui/core'
import * as SETTINGS from '../../../redux/actions/settings'
import * as NOTIFICATION from '../../../utils/notification'
import * as NAVIGATION from '../../../redux/actions/navigation'

const styles = () => ({
    textField: {
        width: '100%',
        marginTop: '0px'
    },
    container: {
        width: '500px',
        margin: 'auto'
    },
    title: {
        fontSize: '1.5em'
    },
    to: {
        marginLeft: '20px'
    }
})

class Settings extends Component {

    state = {
        openUserModal: false,
        userModalType: 'create',
        devicesNr: 0,
        intervalsNr: 4,
        cancelThreshold: 1,
        serverAddress: '',
        username: '',
        password: '',
        port: '',
        tls: '',
        secure: true,
        defaultIntervalsRange: [],
        defaultIntervals: []
    }

    handleChange = event => {
        if (event.target.name === 'intervalsNr') {
            let array = []
            let defaultIntervals = [...this.state.defaultIntervals]
            for (let a = 0; a < event.target.value; a++) {
                array.push(a)
                if (defaultIntervals[a] && defaultIntervals[a].from > 0) continue
                defaultIntervals.push({ from: 0, to: 0 })
            }
            this.setState({ defaultIntervalsRange: array, defaultIntervals })
        }
        this.setState({ [event.target.name]: event.target.value })
    }

    componentDidMount() {
        this.props.changeTab(3)
        this.props.getSettings().then(response => {
            let array = []
            let defaultIntervals = [...this.state.defaultIntervals]
            for (let a = 0; a < response.intervalsNr; a++) {
                array.push(a)
                let split = [0, 0]
                if (response.defaultIntervals && response.defaultIntervals[a] && response.defaultIntervals[a].key)
                    split = response.defaultIntervals[a].key.split('-')

                if (defaultIntervals[a] && defaultIntervals[a].from > 0) continue
                defaultIntervals.push({ from: Number(split[0]), to: Number(split[1]) })
            }

            this.setState({
                defaultIntervals,
                defaultIntervalsRange: array,
                devicesNr: response.devicesNr,
                intervalsNr: response.intervalsNr,
                cancelThreshold: response.cancelThreshold,
                serverAddress: response.smtp ? response.smtp.serverAddress : '',
                username: response.smtp ? response.smtp.username : '',
                password: response.smtp ? response.smtp.password : '',
                port: response.smtp ? response.smtp.port : '',
                secure: response.smtp ? response.smtp.secure : true,
            })
        })
    }

    changeSettings = () => {
        if (this.state.devicesNr < 0 || this.state.intervalsNr < 0 || this.state.cancelThreshold < 0) {
            return NOTIFICATION.error(this.props.languageState.i18n.settings.formError)
        }

        let defaultIntervals = [...this.state.defaultIntervals.slice(0, Number(this.state.intervalsNr)).map(a => a)].map(interval => ({
            key: `${interval.from}-${interval.to}`
        }))

        this.props.editSettings({
            devicesNr: this.state.devicesNr,
            intervalsNr: this.state.intervalsNr,
            cancelThreshold: this.state.cancelThreshold,
            defaultIntervals: defaultIntervals,
            smtp: {
                serverAddress: this.state.serverAddress,
                username: this.state.username,
                password: this.state.password,
                port: this.state.port,
                secure: this.state.secure
            }
        }).then(() => {
            NOTIFICATION.success(this.props.languageState.i18n.settings.edited)
        }).catch(() => {
            NOTIFICATION.error(this.props.languageState.i18n.settings.editError)
        })
    }

    changeInterval = (index, type, value) => {
        let defaultIntervalsCopy = [...this.state.defaultIntervals]

        defaultIntervalsCopy[index][type] = value
        this.setState({ defaultIntervals: defaultIntervalsCopy })
    }

    render() {
        const { classes } = this.props
        const { i18n } = this.props.languageState
        return (
            <Paper style={{ padding: '20px' }}>
                <form className={classes.container} noValidate autoComplete="off">
                    <Typography className={classes.title} component="h2">Setari</Typography>
                    <TextField
                        label={i18n.devicesNr}
                        className={classes.textField}
                        value={this.state.devicesNr}
                        defaultValue="1"
                        type="number"
                        name="devicesNr"
                        onChange={this.handleChange}
                        margin="normal"
                    />

                    <TextField
                        label={i18n.settings.defaultIntervalsNr}
                        defaultValue="4"
                        name="intervalsNr"
                        value={this.state.intervalsNr}
                        className={classes.textField}
                        onChange={this.handleChange}
                        margin="normal"
                    />

                    <TextField
                        label={i18n.settings.cancelTreshold}
                        defaultValue="1"
                        name="cancelThreshold"
                        value={this.state.cancelThreshold}
                        className={classes.textField}
                        onChange={this.handleChange}
                        margin="normal"
                    />

                    {this.state.defaultIntervalsRange.map((value, index) => {
                        return <div>
                            <TextField
                                label={i18n.fromHour}
                                name="intervalsNr"
                                type="number"
                                value={this.state.defaultIntervals[index].from}
                                onChange={event => this.changeInterval(index, 'from', event.target.value)}
                                className={classes.from}
                                margin="normal"
                            />

                            <TextField
                                label={i18n.toHour}
                                name="intervalsNr"
                                type="number"
                                value={this.state.defaultIntervals[index].to}
                                onChange={event => this.changeInterval(index, 'to', event.target.value)}
                                className={classes.to}
                                margin="normal"
                            />
                        </div>
                    })}

                    <Typography style={{ paddingTop: '40px', fontSize: '1.1em' }}> SMTP </Typography>
                    <TextField
                        style={{ marginTop: '0px' }}
                        label={i18n.serverAddress}
                        className={classes.textField}
                        value={this.state.serverAddress}
                        defaultValue="smtp.gmail.com"
                        type="text"
                        name="serverAddress"
                        onChange={this.handleChange}
                        margin="normal"
                    />
                    <TextField
                        label={i18n.username}
                        className={classes.textField}
                        value={this.state.username}
                        type="text"
                        name="username"
                        onChange={this.handleChange}
                        margin="normal"
                    />
                    <TextField
                        label={i18n.password}
                        className={classes.textField}
                        value={this.state.password}
                        type="password"
                        name="password"
                        onChange={this.handleChange}
                        margin="normal"
                    />
                    <TextField
                        label={i18n.port}
                        className={classes.textField}
                        value={this.state.port}
                        defaultValue="465"
                        type="number"
                        name="port"
                        onChange={this.handleChange}
                        margin="normal"
                    />
                    <FormControlLabel
                        control={<Checkbox
                            checked={this.state.secure}
                            onChange={() => this.setState({ secure: !this.state.secure })}
                            margin="normal"
                            name="secure"
                        />}
                        label={i18n.secure}
                    />
                    <div style={{ overflow: 'auto' }}>
                        <Button onClick={this.changeSettings} variant="contained" style={{ float: 'right' }} color='primary'>Salveaza</Button>
                    </div>
                </form>
            </Paper >
        )
    }
}

const mapStateToProps = state => ({
    languageState: state.languageReducer
})

const mapDispatchToProps = dispatch => ({
    changeTab: tab => dispatch(NAVIGATION.changeTab(tab)),
    getSettings: () => dispatch(SETTINGS.get()),
    editSettings: settings => dispatch(SETTINGS.edit(settings))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Settings))
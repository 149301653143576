import axios from '../../utils/axios'
import * as TYPES from '../actionTypes'

export const login = (username, password) => dispatch => axios.post(`/login`, {
    username,
    password
}).then(loginResponse => {
    dispatch({
        type: TYPES.IS_LOGGED,
        payload: {
            isAdmin: loginResponse.data.isAdmin,
            _id: loginResponse.data._id,
            fullName: loginResponse.data.fullName
        }
    })
    return Promise.resolve(loginResponse.data)
})

export const isLogged = () => dispatch => axios.get(`/logged`)
    .then(loginResponse => {
        dispatch({
            type: TYPES.IS_LOGGED,
            payload: {
                isAdmin: loginResponse.data.isAdmin,
                _id: loginResponse.data._id,
                fullName: loginResponse.data.fullName
            }
        })

        return Promise.resolve(loginResponse.data)
    })

export const acceptTerms = () => dispatch => axios.post(`/users/acceptTerms`)

export const logout = () => dispatch => axios.post(`/logout`)
    .then(() => {
        window.location.href = '/'
        dispatch({
            type: TYPES.IS_NOT_LOGGED
        })
    })

export const resetPassword = (username, email) => dispatch => axios.put('/users/resetPassword', {username, email})
